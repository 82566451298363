import {
  ActionsDispatcher,
  createModule,
  EffectParam,
  STATUS,
} from "redux-acron"
import { ActionCreatorsMapObject, AnyAction } from "redux"
import useActions from "@hooks/useActions"
import sagasRunner from "@utils/SagasRunner"
import api from "@services/api"
import { call, put, SagaReturnType, all } from "redux-saga/effects"

export type ClientAccount = {
  id: string
  correoElectronico: string
  telefonoAsociado: string
  rfc: any
  activeweb: any
  ttestadocuenta: any
  appaterno: string
  tipocuenta: any
  primernombre: string
  segundonombre: any
  alias: any
  subtipocuenta: any
  numref: any
  apmaterno: string
  antiguedad: any
  segmentoCobranza: any
  situacionAnticipo: any
  sincronizacionBrm: any
  situacionAdmision: any
  fechaAdmision: any
  cantidadAPagar: any
  cantidadPagada: any
  nombreComercial: any
  cuentaPadre: any
  saldoVencido: any
  solicitarActualizacionDeDatos: any
  clienteDesde: any
  canalIngreso: any
  listaPrecios: any
  pagoAnticipadoVendedor: any
  numeroEmpleado: any
  empresaEmpleado: any
  permitirPagoTtc: any
  blacklist: any
  categoriaBlacklist: any
  giroNegocio: any
  estadoEnBrm: any
  grupoNegocio: any
  combo: any
  cicloFacturacion: any
  diaVencimientoPago: any
  fechaCreacion: any
  creadoPor: any
  fechaActualizacion: any
  actualizadoPor: any
  primaryAddId: any
  tipocuentaprepago: any
  tipocontribuyente: any
  nombrepoliza: any
  orderNumber: any
  orderItemProd: any
  orderItemAction: any
  tipoOferta: any
  curp: any
  nolicencia: any
  credencialelector: any
  compania: any
  direccionFacturacionRpt: any
  direccionFacturacionActivo: any
}

export type ClientAccountsState = {
  list: object
  loadingList: boolean

  total: number
  page: number
  size: number

  filters: object
} // & BASE_INITIAL_STATE<SurveyResponse>

export const CLIENT_ACCOUNTS_MODULE_NAME = "client-accounts" 

const ClientAccountsModule = createModule<ClientAccountsState>({
  name: CLIENT_ACCOUNTS_MODULE_NAME,
  state: {
    // Accounts
    list: [],
    loadingList: false,

    total: 0,
    page: 0,
    size: 15,

    filters: {}
  },
  handlers: {
    //...baseHandlers<SurveyResponse>(),

    list: (state, { payload, status }) => {
      if (status === STATUS.PENDING_STATUS || !status) {
        return state.merge({
          loadingList: true,
          list: [],
          total: 0,
          page: payload.page,
          size: payload.size,
        })
      }

      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({
          loadingList: false,
          list: payload.data,
          total: payload.total,
          page: payload.page,
          size: payload.size,
        })
      }

      if (status === STATUS.FAILURE_STATUS) {
        return state.merge({
          loadingList: false,
        })
      }

      return state
    }
  },

  effects: {
    list: {
      type: "all",
      *callback(
        actions: ActionCreatorsMapObject,
        {
          payload,
        }: EffectParam<{
          page: number
          size: number
          filters: object
          query: object
          sort: string
        }>
      ) {
        const response: SagaReturnType<typeof api.clientAccounts.accounts> =
          yield call(
            api.clientAccounts.accounts,
            payload
          )

        if (response.ok) {
          yield put(
            actions.list(
              {
                data: response.data,
                total: Number(response.headers?.["x-total-count"]),
                page: payload.page,
                size: payload.size,
              },
              STATUS.SUCCESS_STATUS
            )
          )
        } else {
          yield put(actions.list({}, STATUS.FAILURE_STATUS))
        }
      },
    },
  },
})

export default ClientAccountsModule

// Creates a reducer with the initialState and the action handlers
export const reducer = (
  state: any = ClientAccountsModule.initialState,
  action: AnyAction
): ClientAccountsState => {
  const handler = ClientAccountsModule.actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export const rootSagas = sagasRunner(ClientAccountsModule.sagas, CLIENT_ACCOUNTS_MODULE_NAME)

export const actionDispacher = (): ActionsDispatcher =>
  useActions(ClientAccountsModule.actionCreators, [])
