import { answeredMapper, invalidMapper } from "../dashHelper"
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  snVenta: {
    title: 'widgets.snVenta',
    // [Column, Row]
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-satisfaccion',
      type: 'gauge',
      fillMode: '',
      //color: COLORS_CONFIG_10 ,
      showValues: true,
      maxValue: 10,
      decimals: 1,
      //mapper: oneTotenMapper,
      indicators: [
        {
          indicator: 'VENTA_SATISFACCION',
          keyExtract: ['value'],
          value: 'value',
          extras: {
            criticalMomentId: 1,
          },
        },
      ],
    },
  },
  snPostventa: {
    title: 'widgets.snPostventa',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'posventa-satisfaccion',
      type: 'gauge',
      fillMode: '',
      //color: COLORS_CONFIG_10,
      showValues: true,
      maxValue: 10,
      decimals: 1,
      //mapper: oneTotenMapper,
      indicators: [
        {
          indicator: 'SERVICIO_SATISFACCION',
          keyExtract: ['value'],
          value: 'value',
          extras: {
            criticalMomentId: 2,
          },
        },
      ],
    },
  },
  npsVenta: {
    title: 'widgets.npsVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-nps',
      type: 'nps',
      showHistoric: false,
      indicators: [
        {
          indicator: 'VENTA_NPS',
          keyExtract: ['passives', 'detractors', 'promoters', 'value'],
          value: 'value',
          extras: {
            criticalMomentId: 1,
          },
        },
      ],
    },
  },
  npsPostventa: {
    title: 'widgets.npsPostventa',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'posventa-nps',
      type: 'nps',
      color: '#CCC',
      showHistoric: false,
      indicators: [
        {
          indicator: 'SERVICIO_NPS',
          keyExtract: ['passives', 'detractors', 'promoters', 'value'],
          value: 'value',
          extras: {
            criticalMomentId: 2,
          },
        },
      ],
    },
  },
  calidadDatosVenta: {
    title: 'widgets.calidadDatosVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-calidad-gauge',
      type: 'gauge',
      color: '#33b9ce',
      showHistoric: false,
      mapper: invalidMapper,
      indicators: [
        {
          indicator: 'calidad-gauge',
          url: 'survey-responses/values',
          grouped: 'status',
          dataType: 'list',
          keyExtract: ['group', 'count'],
          label: 'group',
          value: 'count',
          singleton: true,
          extras: {
            criticalMomentId: 1,
          },
        },
      ],
    },
  },
  calidadDatosPostventa: {
    title: 'widgets.calidadDatosPostventa',
    position: [2, 2],
    size: [1, 2],
    config: {
      id: 'postventa-calidad-gauge',
      type: 'gauge',
      showHistoric: false,
      color: '#33b9ce',
      mapper: invalidMapper,
      indicators: [
        {
          indicator: 'postventa-calidad-gauge',
          url: 'survey-responses/values',
          grouped: 'status',
          dataType: 'list',
          keyExtract: ['group', 'count'],
          label: 'group',
          value: 'count',
          singleton: true,
          extras: {
            criticalMomentId: 2,
          },
        },
      ],
    },
  },
  porcRespuestasVenta: {
    title: 'widgets.porcRespuestasVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-respuestas-gauge',
      type: 'gauge',
      color: '#33b9ce',
      showHistoric: false,
      mapper: answeredMapper,
      indicators: [
        {
          indicator: 'venta-respuestas-gauge',
          url: 'survey-responses/values',
          grouped: 'status',
          keyExtract: ['group', 'count'],
          dataType: 'list',
          label: 'group',
          value: 'count',
          singleton: true,
          extras: {
            criticalMomentId: 1,
          },
        },
      ],
    },
  },
  porcRespuestasPostventa: {
    title: 'widgets.porcRespuestasPostventa',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'postventa-respuestas-gauge',
      type: 'gauge',
      showHistoric: false,
      color: '#33b9ce',
      mapper: answeredMapper,
      indicators: [
        {
          indicator: 'postventa-respuestas-gauge',
          url: 'survey-responses/values',
          grouped: 'status',
          keyExtract: ['group', 'count'],
          dataType: 'list',
          label: 'group',
          value: 'count',
          singleton: true,
          extras: {
            criticalMomentId: 2,
          },
        },
      ],
    },
  },
  customerJourneyVenta: {
    title: 'widgets.customerJourneyVenta',
    // [Column, Row]
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-customer-journey',
      type: 'multipleGauge',
      fillMode: '',
      colors: ['#33b9ce'],
      showValues: true,
      showHistoric: false,
      //mapper: customerJourneyMapper,
      indicators: [
        {
          indicator: 'VENTA_CUSTOMER_JOURNEY',
          grouped: 'question',
          keyExtract: ['group', 'value', 'groupId'],
          label: 'groupId',
          value: 'value',
          singleton: true,
          extras: {
            criticalMomentId: 1,
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  customerJourneyPostventa: {
    title: 'widgets.customerJourneyPostventa',
    // [Column, Row]
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'posventa-customer-journey',
      type: 'multipleGauge',
      fillMode: '',
      colors: ['#33b9ce'],
      showValues: true,
      showHistoric: false,
      //mapper: customerJourneyMapper,
      indicators: [
        {
          indicator: 'SERVICIO_CUSTOMER_JOURNEY',
          grouped: 'question',
          keyExtract: ['group', 'value', 'groupId'],
          label: 'groupId',
          value: 'value',
          singleton: true,
          extras: {
            criticalMomentId: 2,
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  bestRotativasVenta: {
    title: 'widgets.bestRotativasVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-best-rotativas',
      type: 'ranking',
      indicators: [
        {
          indicator: 'VENTA_ROTATIVAS',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'question',
          sort: { value: 'desc' },
          limit: 3,
          extras: {
            featureExclude: ['groupByTopLocation'],
            criticalMomentId: 1,
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  bestRotativasPostventa: {
    title: 'widgets.bestRotativasPostventa',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'postventa-best-rotativas',
      type: 'ranking',
      indicators: [
        {
          indicator: 'SERVICIO_ROTATIVAS',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'question',
          sort: { value: 'desc' },
          limit: 3,
          extras: {
            featureExclude: ['groupByTopLocation'],
            criticalMomentId: 2,
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  worstRotativasVenta: {
    title: 'widgets.worstRotativasVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-worst-rotativas',
      type: 'ranking',
      indicators: [
        {
          indicator: 'VENTA_ROTATIVAS',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'question',
          sort: { value: 'asc' },
          limit: 3,
          extras: {
            featureExclude: ['groupByTopLocation'],
            criticalMomentId: 1,
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  worstRotativasPostventa: {
    title: 'widgets.worstRotativasPostventa',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'postventa-worst-rotativas',
      type: 'ranking',
      indicators: [
        {
          indicator: 'SERVICIO_ROTATIVAS',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'question',
          sort: { value: 'asc' },
          limit: 3,
          extras: {
            featureExclude: ['groupByTopLocation'],
            criticalMomentId: 2,
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  bestTags: {
    title: 'widgets.bestTagsVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'best-tags',
      type: 'ranking',
      indicators: [
        {
          indicator: 'SENTIMENT',
          keyExtract: ['groupName', 'positive'],
          label: 'groupName',
          //'value': 'positive',
          singleton: true,
          grouped: 'tag',
          sort: { value: 'desc' },
          //limit: 3,
          extras: {
            resultType: 'top',
            trunc: 'HALF_DOWN',
            topSize: 3,
          },
        },
      ],
    },
  },
  bestTagsVenta: {
    title: 'widgets.bestTagsVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-best-tags',
      type: 'ranking',
      indicators: [
        {
          indicator: 'SENTIMENT',
          keyExtract: ['groupName', 'positive'],
          label: 'groupName',
          //'value': 'positive',
          singleton: true,
          grouped: 'tag',
          sort: { value: 'desc' },
          //limit: 3,
          extras: {
            resultType: 'top',
            criticalMomentId: 1,
            trunc: 'HALF_DOWN',
            topSize: 3,
          },
        },
      ],
    },
  },
  bestTagsPostventa: {
    title: 'widgets.bestTagsPostventa',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'postventa-best-tags',
      type: 'ranking',
      indicators: [
        {
          indicator: 'SENTIMENT',
          keyExtract: ['groupName', 'positive'],
          label: 'groupName',
          //'value': 'positive',
          singleton: true,
          grouped: 'tag',
          sort: { value: 'desc' },
          //limit: 3,
          extras: {
            resultType: 'top',
            criticalMomentId: 2,
            trunc: 'HALF_DOWN',
            topSize: 3,
          },
        },
      ],
    },
  },
  worstTags: {
    title: 'widgets.worstTagsVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'worst-tags',
      type: 'ranking',
      indicators: [
        {
          indicator: 'SENTIMENT',
          keyExtract: ['groupName', 'positive'],
          label: 'groupName',
          //'value': 'positive',
          singleton: true,
          grouped: 'tag',
          sort: { value: 'desc' },
          //limit: 3,
          extras: {
            resultType: 'bottom',
            trunc: 'HALF_UP',
            bottomSize: 3,
          },
        },
      ],
    },
  },
  worstTagsVenta: {
    title: 'widgets.worstTagsVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-worst-tags',
      type: 'ranking',
      indicators: [
        {
          indicator: 'SENTIMENT',
          keyExtract: ['groupName', 'positive'],
          label: 'groupName',
          //'value': 'positive',
          singleton: true,
          grouped: 'tag',
          sort: { value: 'desc' },
          //limit: 3,
          extras: {
            resultType: 'bottom',
            criticalMomentId: 1,
            trunc: 'HALF_UP',
            bottomSize: 3,
          },
        },
      ],
    },
  },
  worstTagsPostventa: {
    title: 'widgets.worstTagsPostventa',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'postventa-worst-tags',
      type: 'ranking',
      indicators: [
        {
          indicator: 'SENTIMENT',
          keyExtract: ['groupName', 'positive'],
          label: 'groupName',
          //'value': 'positive',
          singleton: true,
          grouped: 'tag',
          sort: { value: 'desc' },
          //limit: 3,
          extras: {
            resultType: 'bottom',
            criticalMomentId: 2,
            trunc: 'HALF_UP',
            bottomSize: 3,
          },
        },
      ],
    },
  },
  bestGeoLocations: {
    title: 'widgets.bestGeoLocationsVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-best-satisfaccion-zones',
      type: 'ranking',
      calculatedLimit: (data) => Math.min(3, data.length / 2),
      indicators: [
        {
          indicator: 'VENTA_SATISFACCION',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'desc' },
          extras: {
            featureExclude: ['groupByTopLocation'],
          },
        },
      ],
    },
  },


  bestGeoLocationsVenta: {
    title: 'widgets.bestGeoLocationsVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-best-satisfaccion-zones',
      type: 'ranking',
      calculatedLimit: (data) => Math.min(3, data.length / 2),
      indicators: [
        {
          indicator: 'VENTA_SATISFACCION',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'desc' },
          //limit: 3,
          extras: {
            criticalMomentId: 1,
            featureExclude: ['groupByTopLocation'],
          },
        },
      ],
    },
  },
  bestGeoLocationsPostventa: {
    title: 'widgets.bestGeoLocationsPostventa',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'best-satisfaccion-zones',
      type: 'ranking',
      calculatedLimit: (data) => Math.min(3, data.length / 2),
      indicators: [
        {
          indicator: 'SERVICIO_SATISFACCION',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'desc' },
          //limit: 3,
          extras: {
            criticalMomentId: 2,
            featureExclude: ['groupByTopLocation'],
          },
        },
      ],
    },
  },
  worstGeoLocations: {
    title: 'widgets.worstGeoLocationsVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-worst-satisfaccion-zones',
      type: 'ranking',
      calculatedLimit: (data) => Math.min(3, (data.length + 1) / 2),
      indicators: [
        {
          indicator: 'VENTA_SATISFACCION',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'asc' },
          extras: {
            featureExclude: ['groupByTopLocation'],
          },
        },
      ],
    },
  },
  worstGeoLocationsVenta: {
    title: 'widgets.worstGeoLocationsVenta',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'venta-worst-satisfaccion-zones',
      type: 'ranking',
      calculatedLimit: (data) => Math.min(3, (data.length + 1) / 2),
      indicators: [
        {
          indicator: 'VENTA_SATISFACCION',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'asc' },
          //limit: 3,
          extras: {
            criticalMomentId: 1,
            featureExclude: ['groupByTopLocation'],
          },
        },
      ],
    },
  },
  worstGeoLocationsPostventa: {
    title: 'widgets.worstGeoLocationsPostventa',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'worst-satisfaccion-zones',
      type: 'ranking',
      calculatedLimit: (data) => Math.min(3, (data.length + 1) / 2),
      indicators: [
        {
          indicator: 'SERVICIO_SATISFACCION',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'asc' },
          //limit: 3,
          extras: {
            criticalMomentId: 2,
            featureExclude: ['groupByTopLocation'],
          },
        },
      ],
    },
  },
  sentimentAnalysis: {
    title: 'widgets.sentimentAnalysis',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'text-analysis',
      type: 'textAnalysis',
      indicators: [
        {
          indicator: 'SENTIMENT',
          grouped: 'tag',
          keyExtract: ['categories'],
          singleton: false,
          value: 'value',
          extras: {
            //criticalMomentId: 1,
          },
        },
      ],
    },
  },
}
