import { getCommonWidget } from "../../dashWidgetHelper";
import { commonWidgets, getProfuturoAtencion, getProfuturoNpsAcumulado, getProfuturoNpsPorMes, getProfuturoSatisfaccion } from "../commonWidgetsProfuturo";

export default {
    title: 'Acompañamiento Dashboard',
    description: 'Acompañamiento Dashboard',
    backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
    widgets: (filters: FiltersUI) => [
    {
        title: 'Principales Indicadores',
        position: [0, 0],
        size: [4, 1],
         type: 'label',
         config: { color: '#B7AADC' },
    },
    getProfuturoNpsAcumulado({
        position: [0, 1],
        size: [1, 2],
        indicator: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_19'
    }),
    getProfuturoNpsPorMes({
        position: [1, 1],
        size: [1, 2],
        indicator: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_19',
    }),
    getProfuturoSatisfaccion({
        position: [2, 1],
        size: [1, 2],
        indicator: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_7',
        helpText: '¿Cuál es tu nivel de satisfacción general con el trámite completo para ser cliente de Profuturo?'
    }),
    getProfuturoAtencion({
        position: [3, 1],
        size: [1, 2],
        indicator: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_10',
        helpText: 'Ahora hablemos de la atención recibida, ¿cómo fue tu experiencia?'
    }),
    {
        title: 'Atributos de experiencia',
        position: [0, 3],
        size: [4, 1],
         type: 'label',
         config: { color: '#B7AADC' },
    },
    getCommonWidget({
        commonWidgets: commonWidgets,
        name: 'stackedBar',
        title: 'La sencillez para hacer mi trámite para ser cliente',
        position: [0, 4],
        size: [4, 1],
        helpText: 'Pensando en tu experiencia con el trámite que hiciste para ser cliente de Profuturo, ¿cuál es tu nivel de satisfacción con cada uno de los siguientes aspectos',
        indicator: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_5'
    }),
    getCommonWidget({
        commonWidgets: commonWidgets,
        name: 'stackedBar',
        title: 'La duración del trámite para ser cliente (tiempo que transcurrió entre que inició y terminó)',
        position: [0, 5],
        size: [4, 1],
        helpText: 'Pensando en tu experiencia con el trámite que hiciste para ser cliente de Profuturo, ¿cuál es tu nivel de satisfacción con cada uno de los siguientes aspectos',
        indicator: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_4'
    }),
    getCommonWidget({
        commonWidgets: commonWidgets,
        name: 'stackedBar',
        title: 'La herramienta utilizada para la captura de huellas y datos durante el trámite',
        position: [0, 6],
        size: [4, 1],
        helpText: 'Pensando en tu experiencia con el trámite que hiciste para ser cliente de Profuturo, ¿cuál es tu nivel de satisfacción con cada uno de los siguientes aspectos',
        indicator: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_3'
    }),
    getCommonWidget({
        commonWidgets: commonWidgets,
        name: 'stackedBar',
        title: 'La asesoría brindada estuvo a la altura de mis espectativas',
        position: [0, 7],
        size: [4, 1],
        helpText: 'Pensando en la atención recibida, ¿qué tan de acuerdo te encuentras con cada una de las siguientes afirmaciones?',
        indicator: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_11'
    }),
    getCommonWidget({
        commonWidgets: commonWidgets,
        name: 'stackedBar',
        title: 'Me mostraron claramente los beneficios de realizar Aportaciones Voluntarias',
        position: [0, 8],
        size: [4, 1],
        helpText: 'Pensando en la atención recibida, ¿qué tan de acuerdo te encuentras con cada una de las siguientes afirmaciones?',
        indicator: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_12'
    }),
    getCommonWidget({
        commonWidgets: commonWidgets,
        name: 'stackedBar',
        title: 'Hubo compromiso y predisposición para resolver mis dudas y consultas',
        position: [0, 9],
        size: [4, 1],
        helpText: 'Pensando en la atención recibida, ¿qué tan de acuerdo te encuentras con cada una de las siguientes afirmaciones?',
        indicator: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_13'
    }),
    getCommonWidget({
        commonWidgets: commonWidgets,
        name: 'stackedBar',
        title: 'Me ofrecieron o realizaron un estudio personalizado para el retiro',
        position: [0, 10],
        size: [4, 1],
        helpText: 'Pensando en la atención recibida, ¿qué tan de acuerdo te encuentras con cada una de las siguientes afirmaciones?',
        indicator: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_14'
    }),
    getCommonWidget({
        commonWidgets: commonWidgets,
        name: 'wordCount',
        title: 'Comentarios',
        position: [0, 11],
        size: [4, 2],
        helpText: 'Por último, ¿qué crees que podríamos mejorar a futuro en el trámite para ser cliente de Profuturo?',
        indicator: 'fuerza_ventas_traspasos_encuesta_pregunta_15'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de Sentimientos',
      position: [0, 13],
      size: [4, 4],
      indicator: 'SENTIMENT'
    }),
]} as DasboardConfig