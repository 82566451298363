import { isArray } from "lodash"

export const filterLogicals = (logicals: LogicalDistribution[], selected: number[]): number[] => {
  const logicalsArray: any = logicals.map((logical) => {
    return selected.includes(logical.id)
      ? getLeaves(logical)
      : filterLogicals(logical.children, selected)
  })
  return [].concat(...logicalsArray)
}

const getLeaves = (logicals: LogicalDistribution) => {
  return getLeavesByField(logicals, 'id')
}

export const getChildrens = (logicals: LogicalDistribution | LogicalDistribution[]): LogicalDistribution[] => {
  if (logicals === undefined || logicals === null) {
    return []
  }

  if (isArray(logicals)) {
    const arrayChildren: any = logicals.map((child) => getChildrens(child))
    return [].concat(...arrayChildren)
  }

  const children: any =
    logicals.children === undefined || logicals.children.length === 0
      ? [logicals]
      : logicals.children.map((child) => getChildrens(child))
  return [].concat(...children)
}

export const getLeavesByField = (logicals: LogicalDistribution, field: string) => {
  const leaves: any =
    logicals.children === undefined || logicals.children.length === 0
      ? [logicals[field]]
      : logicals.children.map((child) => getLeavesByField(child, field))
  return [].concat(...leaves)
}

export const selectedLogicals = (logicals: LogicalDistribution[], selected: number[]): LogicalDistribution[] => {
  const logicalsArray: any = logicals.map((logical) => {
    return selected.includes(logical.id)
      ? logical
      : selectedLogicals(logical.children, selected)
  })
  return [].concat(...logicalsArray)
}
