import { ColumnsType } from 'antd/lib/table'
import {
  ClientDashboardConfig,
  DASHBOARDS,
  DashboardList,
} from '../dashboard/dashboardCommons'
import { ActionPlansConfig, OmoikaneConfig } from '../omoikane/omoikaneCommons'
import { ReactNode } from 'react'

export enum FILTER_TYPES {
  DATES = 'dates',
  LOGICALS = 'logicalDistributions',
  GEOS = 'geographicDistributions',
  CRITICAL_MOMENTS = 'criticalMoments',
  CLUSTERS = 'clusters',
  DYNAMIC = 'dynamic',
}

export enum METADADATA_LOAD {
  LOGICALS = 'logicalDistributions',
  GEOS = 'geographicDistributions',
  CRITICAL_MOMENTS = 'criticalMoments',
  CLUSTERS = 'clusters',
  DYNAMIC_REPORT_FIELDS = 'dynamicReportsFields',
  WORKFLOWS = 'workflows',
}

export interface RatingRanges {
  defaultRatingMin: number
  defaultRatingMax: number
}

export enum SurveyResponsesTab {
  ANSWERED = 'ANSWERED',
  NOT_ANSWERED = 'NOT_ANSWERED',
  INVALID = 'INVALID',
  IN_PROGRESS = 'IN_PROGRESS'
}

export interface SuveyResponsesIndicatorsConfig {
  nps?: string
  csatGeneral?: string
}

export interface SuveyResponsesConfig {
  url: string
  tableColumns: ColumnsType<any> | undefined
  tabs: SurveyResponsesTab[]
  type: 'post' | 'get' | 'post-context'
  indicators?: SuveyResponsesIndicatorsConfig
}

export interface AlertDetailField {
  label: string
  getValue: (data: any) => string
}

export interface NotificationsConfig {
  enabled: boolean
}

export interface HelpDefinition {
  alertDetail?: ReactNode
}

export interface QuestionOrder{
    questionName: String
    order: number
}

export interface ClientConfigInfo {
  // General
  vocBackendUrl: string
  lcmBackendUrl: string
  caseBackendUrl?: string
  publicUrl: string | undefined
  logoSmall: any
  logo: any
  supportsInternationalization: boolean
  decimals: number | undefined
  ratingRanges?: RatingRanges
  help: HelpDefinition
  // Filters
  filtersFromDateSubstract: number
  filtersToDateSubstract: number
  filters: FILTER_TYPES[]
  // Dashboard
  dashboards: ClientDashboardConfig
  menuDashboards: DASHBOARDS[]
  useBackDashboards: boolean
  staticDashboards: DashboardList
  sentimentCommentsIndicatorName: string
  useDefaultCriticalMoment?: boolean
  // Metadata
  metadata: METADADATA_LOAD[]
  criticalMomentsRootId: number
  criticalMomentFilterType: 'select' | 'tree'
  geosRootId: number
  logicalsRootId: number
  getFilterCriticalMoments: (
    filtersData: Record<string, any>,
    userProfiles: UserProfile[]
  ) => CriticalMoment[]
  getFilterCriticalMomentDefault: (
    filtersData: Record<string, any>,
    userProfiles: UserProfile[]
  ) => number
  // Reports
  msExcelReportExt: string
  // Omoikane
  omoikane: OmoikaneConfig
  // Action Plan
  actionPlans: ActionPlansConfig,
  // Survey Responses
  surveyResponses: SuveyResponsesConfig,
  // Alert
  alertDetailFields: AlertDetailField[],
  useAlertWorkflows?: boolean,
  // Notifications
  notificationsConfig?: NotificationsConfig
  // Customer data info
  userPhoneNumber?: boolean
  pageSize?: number
  questionsCustomerName?: string[]
  questionsOrder?: QuestionOrder[]
}
