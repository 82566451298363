import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './styles/main.less'
import App from './App'
import history from '@utils/history'
import '@utils/i18n'
import createStore from './store'
import routeConfig from './routes'
import reportWebVitals from './reportWebVitals'

const store = createStore(history)
const routes = routeConfig(store)

ReactDOM.render(
  <App store={store} routes={routes} history={history}  />,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
