import { get, isArray, isObject, isString } from 'lodash'

/* No tocar los 3 primeros colores porque estan asociados con los widgets */
export const DEFAULT_COLORS = [
  // '#A2D1A2', // Positivo - verde old
  // '#F5B49F', // Negativo - rojo old
  // '#A3D1D6', // Neutro - azul old
  '#88DD76', // Positivo - verde
  '#DB7476', // Negativo - rojo
  '#8CF0EA', // Neutro - azul
  '#FAEF6B', // amarillo
  '#B29BC2',
  '#F3B6D8',
  '#FFF9B1',
]

export const DEFAULT_NPS_LABELS = {
  promoters: 'Promotores',
  passives: 'Neutros',
  detractors: 'Detractores'
}

export const COLORS_CONFIG = [
  {
    color: DEFAULT_COLORS[1],
    condition: '<=' as const,
    key: 'value',
    value: 40,
  },
  {
    color: DEFAULT_COLORS[2],
    condition: '>' as const,
    key: 'value',
    value: 40,
  },
  {
    color: DEFAULT_COLORS[0],
    condition: '>' as const,
    key: 'value',
    value: 80,
  },
]

export const COLORS_CONFIG_GAUGE_5 = [
  // Negativo
  {
    color: DEFAULT_COLORS[1],
    condition: '<' as const,
    key: 'value',
    value: 3,
  },
  // Neutro
  {
    color: DEFAULT_COLORS[2],
    condition: '>=' as const,
    key: 'value',
    value: 3,
  },
  // Positivo
  {
    color: DEFAULT_COLORS[0],
    condition: '>=' as const,
    key: 'value',
    value: 4,
  },
]

export const COLORS_CONFIG_BAR = [
  // Negativo
  {
    color: DEFAULT_COLORS[1],
    condition: '<=' as const,
    key: 'value',
    value: 6,
  },
  // Neutro
  {
    color: DEFAULT_COLORS[2],
    condition: '>' as const,
    key: 'value',
    value: 6,
  },
  // Positivo
  {
    color: DEFAULT_COLORS[0],
    condition: '>' as const,
    key: 'value',
    value: 8,
  },
]

export const DEFAULT_COLORS_WIDGETS = [
  '#FF7410',
  '#50D5FF',
  '#F1DFFF',
  '#FA4C5C',
  '#019E85',
  '#EDF167',
  '#B29BC2',
  '#A2D1A2',
  '#F5B49F',
  '#A3D1D6',
  '#64E883',
  '#B29BC2',
  '#C878E3',
  '#F3B6D8',
  '#FFF9B1',
  '#777933',
  '#A668A8',
  '#9A9CCD',
  '#9C6D76',
  '#C0CB0B',
  '#878972',
  '#0C84AD',
  '#C357F2',
  '#F0EC8A',
  '#FF7410',
  '#50D5FF',
  '#F1DFFF',
  '#FA4C5C',
  '#019E85',
  '#EDF167',
  '#B29BC2',
  '#A2D1A2',
  '#F5B49F',
  '#A3D1D6',
  '#64E883',
  '#C878E3',
  '#986CAF',
  '#F3B6D8',
  '#FFF9B1',
  '#C357F2',
  '#777933',
  '#A668A8',
  '#9A9CCD',
  '#9C6D76',
  '#C0CB0B',
  '#878972',
  '#0C84AD',
]


/**
 * colors
 */

const processCondition = (value: number, colorConfig: ColorConfiguration) => {

  //console.log('processing condition color!!!!')
  //console.log(colorConfig)
  //console.log(value)

  switch (colorConfig.condition) {
    case '<':
      return value < colorConfig.value
    case '>':
      return value > colorConfig.value
    case '=':
      return value === colorConfig.value
    case '<=':
      return value <= colorConfig.value
    case '>=':
      return value >= colorConfig.value
    default:
      return true
  }
}


export const getColor = (
  colors: string | string[] | Array<ColorConfiguration>,
  idx: number,
  value: number | undefined = undefined
): string => {
  //console.log('get color!!!!')
  //console.log(colors)
  //console.log(value)

  if (isString(colors)) {
    return colors
  } else if (isArray(colors)
    && colors[idx] !== undefined
    && isString(colors[idx])) {
    return colors[idx].toString()
  } else if (value !== undefined
    && isArray(colors)
    && colors[0] !== undefined
    && isObject(colors[0])) {
    return colors
      // @ts-ignore
      .filter((clr: ColorConfiguration) => processCondition(value, clr))
      // @ts-ignore
      .reverse()[0]?.color
  }

  return DEFAULT_COLORS[idx] ? DEFAULT_COLORS[idx] : DEFAULT_COLORS[0]
}
