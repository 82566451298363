import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/survey-stats',
  layout: true,
  getComponent: async () => {
    const SurveysAdminLayout = await import(
      /* webpackChunkName: "surveys-modules" */ './components/SurveyStatsLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "surveys-modules" */ './SurveyStatsModule'
    )

    injectReducer(store, { key: 'surveyStats', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: SurveysAdminLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'surveys.list_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const SurveyTableView = await import('../SurveyStats/components/SurveyTableView')
        return { children: SurveyTableView.default }
      },
    },

    {
      path: '/detail/:id/',
      title: 'surveys.detail_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const SurveyDetailView = await import('./components/SurveyDetailView')
        return { children: SurveyDetailView.default, }
      },
    },
  ],
})

