export const filterGeos = (geos: GeoDistribution[], selected: number[]) => {
  return getFilterField(geos, selected, 'id')
}

export const getFilterField = (geos: GeoDistribution[], selected: number[], field: string) => {
  const geosArray: any = geos.map((geo) => {
    return selected.includes(geo.id)
      ? getLeaves(geo, field)
      : getFilterField(geo.children, selected, field)
  })
  return [].concat(...geosArray)
}

const getLeaves = (geo: GeoDistribution, field: string) => {
  const leaves: any =
    geo.children === undefined || geo.children.length === 0
      ? [geo[field]]
      : geo.children.map((child) => getLeaves(child, field))
  return [].concat(...leaves)
}

export const getGeoLocationFields = (geos: GeoDistribution[], selected: number[], field: string) => {
  const geosArray: any = geos.map((geo) => {
    return selected.includes(geo.id)
      ? [geo[field]]
      : getGeoLocationFields(geo.children, selected, field)
  })
  return [].concat(...geosArray)
}

export const getCriticalMomentFields = (cms: CriticalMoment[], selected: number[], field: string) => {
  const cmsArray: any = cms.map((cm) => {
    return selected.includes(cm.id)
      ? cm.id == 0 ? [] : [cm[field]]
      : getCriticalMomentFields(cm.children, selected, field)
  })
  return [].concat(...cmsArray)
}

export const getWorkflowNames = (cms: string[], workflows: any[]) => {
  return cms !== undefined && cms.length > 0
    && workflows !== undefined && workflows.length > 0
    ? workflows
      .filter(wkf => cms.map(item => item.toString()).includes(wkf.criticalMomentCode))
      .map(wkf => wkf.name)
      .flat(1)
    : []
}

export const getLogicalLocationFields = (logicals: LogicalDistribution[], selected: number[], field: string) => {
  const geosArray: any = logicals.map((logical) => {
    return selected.includes(logical.id)
      ? [logical[field]]
      : getLogicalLocationFields(logical.children, selected, field)
  })
  return [].concat(...geosArray)
}