import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'
import useClientConfig from 'config/clients/useClientConfig'
import useClientTranslation from 'config/clients/useClientTranslation'


// Sync route definition
export default (store: Store, dashboard: string): RouteConfig => {
  const { getDashboardConfig } = useClientConfig()
  const config = getDashboardConfig(dashboard)

  //console.log('Configurando dashboard ' + dashboard)
  //console.log(config)
  //console.log(dashboard)
  
  return ({
    path: config.path, //'/dashboard',
    layout: true,
    getComponent: async () => {
      const DashboardLayout = await import('./components/DashboardLayout')
      const { reducer, rootSagas, } = await import('./DashboardModule')

      injectReducer(store, { key: config.reducerKey /*'dashboard'*/, reducer, })
      injectSagas(store, rootSagas)

      return {
        children: DashboardLayout.default,
      }
    },
    childRoutes: [
      {
        path: '/',
        title: config.title,
        getComponent: async () => {
          const DashboardView = await import('./components/DashboardView')
          return {
            children: DashboardView.default,
          }
        },
      },
    ],
  })
}

