import perdidasTotales from './perdidasTotales'
import general from './general'

export const gnpStaticDashboards: Record<string, DasboardConfig> = {
  '11607': {
    title: 'Perdidas totales Dashboard',
    description: 'Perdidas totales Dashboard',
    widgets: perdidasTotales.widgets,
  },
  '0': {
    title: 'General Dashboard',
    description: 'General Dashboard',
    widgets: general.widgets,
  }
}