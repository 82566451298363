import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/logical-distributions',
  layout: true,
  getComponent: async () => {
    const LogicalDistributionsLayout = await import(
      /* webpackChunkName: "logical-distributions-modules" */ './components/LogicalDistributionsLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "logical-distributions-modules" */ './LogicalDistributionsModule'
    )

    injectReducer(store, { key: 'logicalDistributions', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: LogicalDistributionsLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'logical_distributions.list_title',
      getComponent: async () => {
        const LogicalDistributionsListView = await import(
          /* webpackChunkName: "logical-distributions-modules" */ './components/LogicalDistributionsListView'
        )
        return {
          children: LogicalDistributionsListView.default,
        }
      },
    },

    {
      title: 'logical_distributions.new_title',
      path: '/new',
      getComponent: async () => {
        const LogicalDistributionsFormView = await import(
          /* webpackChunkName: "logical-distributions-modules" */ './components/LogicalDistributionsFormView'
        )
  
        return {
          children: LogicalDistributionsFormView.default,
        }
      },
    },

    {
      title: 'logical_distributions.new_title',
      path: '/edit/:id',
      getComponent: async () => {
        const LogicalDistributionsFormView = await import(
          /* webpackChunkName: "logical-distributions-modules" */ './components/LogicalDistributionsFormView'
        )
  
        return {
          children: LogicalDistributionsFormView.default,
        }
      },
    },
  ],
})
