import { useRef, useEffect } from 'react'

type Previous<T> = T | undefined

/**
 * Hook to save a previous value.
 * @param value Any
 */
export default function usePrevious<T>(value: T): Previous<T> {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}