import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/alerts',
  layout: true,
  getComponent: async () => {
    const AlertsLayout = await import(
      /* webpackChunkName: "alerts-modules" */ './components/AlertsLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "alerts-modules" */ './AlertsModule'
    )

    injectReducer(store, { key: 'alerts', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: AlertsLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/list',
      title: 'alerts.list_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const AlertsTableView = await import(
          /* webpackChunkName: "alerts-modules"*/ './components/AlertsTableView'
        )
        return {
          children: AlertsTableView.default,
        }
      },
    },

    {
      path: '/detail/:id/',
      title: 'alerts.detail_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const AlertDetailView = await import(
          /* webpackChunkName: "alerts-modules"*/ './components/AlertDetailView'
        )
        return {
          children: AlertDetailView.default,
        }
      },
    },

    
    {
      path: '/stats',
      title: 'alerts.stats_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const AlertsStatsView = await import(
          './components/AlertsStatsView'
        )
        return {
          children: AlertsStatsView.default,
        }
      },
    },
    
    // {
    //   title: '', // Here you can use a plain string o a transalation key
    //   path: '/other-sub-path',
    //   getComponent: async () => {
    //     const OtherView = await import(
    //       /* webpackChunkName: "alerts-modules"*/ './components/OtherView'
    //     )
    //
    //     return {
    //       children: OtherView.default,
    //     }
    //   }
    // },
  ],
})

