import React from 'react'
import { Avatar, Divider, Button, Typography, Checkbox } from 'antd'
import { getAvatarLetters } from '@utils'
import logoOxxo from '@assets/images/logo-oxxo.png'

import './ProfileMenu.less'
import useClientConfig from 'config/clients/useClientConfig'
import useClientTranslation from 'config/clients/useClientTranslation'

type PropsType = {
  onLogout: () => void
  profileData: UserAccount
}

const getFullName = (profileData: UserAccount) => {
  return (profileData.firstName !== undefined ? profileData.firstName : '')
    + ' '
    + (profileData.lastName !== undefined ? profileData.lastName : '')
}

const {getClientName, getClientLogoSmall} = useClientConfig()

const ProfileMenu = ({ onLogout, profileData, }: PropsType): JSX.Element => {
  const { translate } = useClientTranslation()
  
  return (
    <div className='profile-menu'>
      <div className='profile-info'>
        <Avatar size={60}>
          {getAvatarLetters(profileData.firstName, profileData.lastName)}
        </Avatar>
        <h4>{getFullName(profileData)}</h4>
        <h5>{profileData.email}</h5>
      </div>
      <div className='row-container-logo'>
        <Typography className='title-row-container-logo'>{getClientName()}</Typography>
        <img src={getClientLogoSmall()} className='image-row-container-logo'/>
      </div>
      {/*<Typography className='admin-profile-text'>Administrar mi perfil</Typography>
      <div className='palette-color-selection-container'>
        <Typography className='text-palette-color'>Paleta de colores seleccionada</Typography>
        <Checkbox>Paleta tradicional</Checkbox>
        <Typography className='text-info-palette-color'>
          Puedes seleccionar la paleta de colores desde la administración de tu perfil.
        </Typography>
      </div>*/}
      <div onClick={onLogout} className='on-logout-container'>{translate('general.logout')}</div>

      {/* <Menu>
        { <Menu.Item icon={<Icon theme='outlined' type='Edit' />}>
          Editar Perfil
        </Menu.Item>
        <Menu.Item icon={<Icon theme='outlined' type='QuestionCircle' />}>
          Ayuda
        </Menu.Item>
        <Menu.Item icon={<Icon theme='outlined' type='Warning' />}>
          Soporte
        </Menu.Item> }
      </Menu> */}
      {/* <div className='legals-links'>
        <Button type='text' href='#'>
          Legales
        </Button>
        <span className='dot-divider' />
        <Button type='text' href='#'>
          Politicas de Privacidad
        </Button>
      </div> */}
    </div>
  )
}

export default ProfileMenu
