/**
 * Parse the filters names and formats.
 * @param {Object} filters
 * @returns {Object}
 */
export default (filters: ActionPlanFilters): Filters => {
  //console.log('Parsing filters Action plan')
  //console.log(filters)

  if (!filters) {
    return {}
  }

  const createCustomFilters = (filters: any) => {
    const filter =
      filters &&
      Object.keys(filters).length > 0 &&
      Object.keys(filters).filter((key: any) => filters[key] !== undefined)
        .length > 0
        ? {
            filter: Object.keys(filters)
              .filter(
                (key: any) =>
                  ['login', 'email', 'description', 'code'].includes(key) &&
                  filters[key] !== undefined
              )
              .map((key: any) => key + '=eq=%' + filters[key] + '%')
              .join(';'),
          }
        : {}

    return filter
  }

  //console.log('FILTERSSSSSSSSSSSSS')
  //console.log(filters)

  return {
    geoLocationInclude: filters.geographicDistributions
      ? filters.geographicDistributions.join(',')
      : [],
    ...createCustomFilters(filters),
  }
}
