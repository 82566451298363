import {
  ActionsDispatcher,
  createModule,
  EffectParam,
  STATUS,
} from "redux-acron"
import { ActionCreatorsMapObject, AnyAction } from "redux"
import useActions from "@hooks/useActions"
import sagasRunner from "@utils/SagasRunner"
import api from "@services/api"
import { call, put, SagaReturnType, all } from "redux-saga/effects"

export type MessageNotification = {
  id: number
  type: string
  number: number
  schedule: string
  version: any
  token: string
  destination: string
  processedAt: string
  expirationTime: string
  expirationSla: string
  createdAt: string
  queuedAt: string
  updatedAt: any
  sendError: any
  trackingId: string
  duration: string
  workflowName: string
  entityType: string
  entityId: string
  last: boolean
  surveyId: any
  messageId: number
  sentAt: string
  answeredAt: any
  trackingStatus: string
  errorReason: any
  errorDescription: any
  prevWorkflow: any
  accountId: string
  answerExpirationTime: string
  answerStatus: any
  answerExpirationDuration: any
  message: any
}

export type MessageNotificationsState = {
  list: object
  loadingList: boolean

  total: number
  page: number
  size: number

  filters: object
} // & BASE_INITIAL_STATE<SurveyResponse>

export const MESSAGE_NOTIFICATION_MODULE_NAME = "message-notifications"

const MessageNotificationsModule = createModule<MessageNotificationsState>({
  name: MESSAGE_NOTIFICATION_MODULE_NAME,
  state: {
    list: [],
    loadingList: false,

    total: 0,
    page: 0,
    size: 15,

    filters: {}
  },
  handlers: {
    //...baseHandlers<SurveyResponse>(),

    list: (state, { payload, status }) => {
      if (status === STATUS.PENDING_STATUS || !status) {
        return state.merge({
          loadingList: true,
          list: [],
          total: 0,
          page: payload.page,
          size: payload.size,
        })
      }

      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({
          loadingList: false,
          list: payload.data,
          total: payload.total,
          page: payload.page,
          size: payload.size,
        })
      }

      if (status === STATUS.FAILURE_STATUS) {
        return state.merge({
          loadingList: false,
        })
      }

      return state
    }
  },

  effects: {
    list: {
      type: "all",
      *callback(
        actions: ActionCreatorsMapObject,
        {
          payload,
        }: EffectParam<{
          accountId: number
          page: number
          size: number
          filters: object
          query: object
          sort: string
        }>
      ) {
        const response: SagaReturnType<typeof api.clientAccounts.messageNotifications> =
          yield call(
            api.clientAccounts.messageNotifications,
            payload
          )

        if (response.ok) {
          yield put(
            actions.list(
              {
                data: response.data,
                total: Number(response.headers?.["x-total-count"]),
                page: payload.page,
                size: payload.size,
              },
              STATUS.SUCCESS_STATUS
            )
          )
        } else {
          yield put(actions.list({}, STATUS.FAILURE_STATUS))
        }
      },
    },
  },
})

export default MessageNotificationsModule

// Creates a reducer with the initialState and the action handlers
export const reducer = (
  state: any = MessageNotificationsModule.initialState,
  action: AnyAction
): MessageNotificationsState => {
  const handler = MessageNotificationsModule.actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export const rootSagas = sagasRunner(MessageNotificationsModule.sagas, MESSAGE_NOTIFICATION_MODULE_NAME)

export const actionDispacher = (): ActionsDispatcher =>
  useActions(MessageNotificationsModule.actionCreators, [])
