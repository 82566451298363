
import logoGnp from '@assets/logos/gnp-logo.png'
import logoGnpSmall from '@assets/logos/gnp-logo-small.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments } from '../dashboard/dashHelper'
import { gnpStaticDashboards } from '../dashboard/gnp/staticDashboards'
import deafultHelpDefinition from '../help/defaultHelp'

export const gnpConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://gnp.limetropy.com/api/',
    lcmBackendUrl: 'https://gnp.limetropy.com/backend/core/api/ui/',
    //vocBackendUrl: 'http://localhost:9000/api/',
    publicUrl: undefined,
    logoSmall: logoGnpSmall,
    logo: logoGnp,
    filtersFromDateSubstract: 1,
    filtersToDateSubstract: 0,
    decimals: undefined,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.GEOS, FILTER_TYPES.CRITICAL_MOMENTS],
    supportsInternationalization: false,
    msExcelReportExt: 'xlsx',
    menuDashboards: [DASHBOARDS.DEFAULT],
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    geosRootId: 1,
    logicalsRootId: 0,
    criticalMomentsRootId: 0,
    criticalMomentFilterType: 'select',
    actionPlans: {
        geoLeafLevel: '',
        useWorstTags: true
    },
    omoikane: {
        decimals: 2,
        enabledTabs: [],
        indicators: [],
        monthdsCoverage: 2,
        sentiment: {
            indicators: {},
            properties: {
                sentiment: '',
                percentage: '',
                sentimentLocal: '',
                percentageLocal: ''     
            }
        },
        customerJourney: {},
        cxRay: { indicators: {} },
        clusters: {},
    },
    useBackDashboards: true,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.WORKFLOWS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.gnp,
        default: DASHBOARDS_CONFIGS.gnp[DASHBOARDS.DEFAULT]
    },
    staticDashboards: gnpStaticDashboards,
    surveyResponses: {
        url: 'survey-responses',
        type: 'get',
        tableColumns: undefined,
        tabs: [SurveyResponsesTab.ANSWERED, SurveyResponsesTab.NOT_ANSWERED],
    },
    alertDetailFields: [],
    help: deafultHelpDefinition
}
