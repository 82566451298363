import React from 'react'
import parseConfig from '@utils/routing/RouterParserComplex'
import PublicLayout from '@layouts/PublicLayout'
import ErrorsLayout from '@layouts/ErrorsLayout'

import { Switch } from 'react-router-dom'
import Login from './Login'
import PasswordRecovery from './PasswordRecovery'
import ResetPassword from './ResetPassword'

import Companies from './Companies'
import Roles from './Roles'
import Users from './Users'
import Surveys from './Surveys'
import Alerts from './Alerts'
import CriticalMoments from './CriticalMoments'
import LogicalDistributions from './LogicalDistributions'
import GeoDistributions from './GeoDistributions'
import Dashboard from './Dashboard'
import Reports from './Reports'
import ActionPlans from './ActionPlans'
import EmployeeExp from './EmployeeExp'
import SuplierExp from './SuplierExp'
import SocialListening from './SocialListening'
import OmoikaneAi from './OmoikaneAi'
import PowerIntelligence from './PowerIntelligence'
import EpsilonLayout from '@layouts/EpsilonLayout'
import SurveyStats from './SurveyStats'
import useClientConfig from 'config/clients/useClientConfig'

import { DASHBOARDS } from 'config/clients/dashboard/dashboardCommons'
import ClientAccounts from './MasterAccountant/ClientAccounts'
import AccountMessages from './MasterAccountant/AccountMessages'
import MessageNotifications from './MasterAccountant/MessageNotifications'
import NotificationSurveys from './MasterAccountant/NotificationSurveys'

const AppRouter = (mainStore: Store): JSX.Element => {
  const { getClientMenuDashboards } = useClientConfig()
  return (
    <>
      <Switch>
        {
          parseConfig([
            {
              path: '/login',
              component: PublicLayout,
              layout: true,
              childRoutes: [
                Login(),
                PasswordRecovery(),
                ResetPassword(),
              ],
              onEnter: (store, history) => {
                const { auth, appData, account, } = store.getState()

                if (auth && auth.token && account.data.activated && !account.data.temporalPass) {
                  history.replace({ pathname: appData.menuItems[0].path, })
                }

                if (auth && auth.token && (!account.data.activated && account.data.temporalPass)) {
                  history.push('/login/reset', { firstLogin: true, })
                }
              },
            },

            {
              path: '/admin',
              component: EpsilonLayout,
              layout: true,
              childRoutes: [
                Companies(mainStore),
                Roles(mainStore),
                Users(mainStore),
                CriticalMoments(mainStore),
                LogicalDistributions(mainStore),
                GeoDistributions(mainStore),
                //DashboardBuilder(mainStore),
              ],
              onEnter: (store, history) => {
                const { auth, } = store.getState()
                // if (!auth || !auth.token) {
                //   history.replace({ pathname: '/login' })
                // }
                if (!auth || !auth.token) {
                  history.replace('/login')
                }
              },
            },

            // {
            //   path: '/survey',
            //   component: SurveyLayout,
            //   layout: true,
            //   childRoutes: [
            //     Survey(mainStore),
            //   ],
            // },

            {
              path: '/',
              component: EpsilonLayout,
              layout: true,
              childRoutes: [
                ...getClientMenuDashboards().map((dash: DASHBOARDS) => {
                  return Dashboard(mainStore, dash)
                }),
                // Others
                Surveys(mainStore),
                Reports(mainStore),
                Alerts(mainStore),
                ActionPlans(mainStore),
                EmployeeExp(mainStore),
                SuplierExp(mainStore),
                SocialListening(mainStore),
                OmoikaneAi(mainStore),
                PowerIntelligence(mainStore),
                SurveyStats(mainStore),
                ClientAccounts(mainStore),
                AccountMessages(mainStore),
                MessageNotifications(mainStore),
                NotificationSurveys(mainStore)
              ],
              onEnter: (store, history, location) => {
                const { auth, appData, account, } = store.getState()

                if (auth && auth.token && account.data.activated && !account.data.temporalPass && location.pathname === '/') {
                  history.replace({ pathname: appData.menuItems[0].path, })
                }

                if (!auth || !auth.token) {
                  const regex = /(alerts\/detail)\/(\S+)/gmi
                  if (location.pathname.match(regex)) {
                    sessionStorage.setItem('beforeLogin', location.pathname)
                  } else {
                    sessionStorage.removeItem('beforeLogin')
                  }

                  history.replace('/login')
                }
              },
            },

            {
              path: '*',
              component: ErrorsLayout,
            },
          ])
        }
      </Switch>
    </>
  )
}

AppRouter.displayName = 'AppRouter'

export default AppRouter