import { isGeoLocationLeaf } from '../dashHelper'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'

export const geoLeafCheckRender = (filters: FiltersUI, filtersData: Record<string, any>) => {
  /*const excluded = ['postventa-satisfaccion-dist', 'postventa-satisfaction-zona']
    .includes(wdg?.config?.id ?? '') && isGeoLocationLeaf(filters, filtersData)
  return filters !== undefined
    && filters.criticalMoments !== undefined
    && filters.criticalMoments !== null
    && filters.criticalMoments.includes(2)
    && !excluded*/
}

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary
}
