import { OmoikaneConfig, OmoikaneTab } from "./omoikaneCommons";

export const BENAVIDES_CJ_ORDER = {
    // Venta
    10155: 0, // Ambiente interior
    12151: 1, // Ambiente exterior
    10156: 2, // Personal
    10051: 3, // Satisfacción geeral
    10053: 4, // NPS
  }

export const omoikaneBenavides: OmoikaneConfig = {
    decimals: 2,
    enabledTabs: [
        OmoikaneTab.CUSTOMER_JORUNEY,
        OmoikaneTab.CX_RAY,
        OmoikaneTab.CX_ROAD_MAP,
        //OmoikaneTab.CLUSTERS
    ],
    monthdsCoverage: 2,
    indicators: [
        {
            indicator: 'GENERAL_CUSTOMER_JOURNEY',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'SENTIMENT',
            key: 'sentiment-enc-general',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [1]
            }
        },
        {
            indicator: 'generalpregunta_1',
            key: 'satisfaction-enc-general',
            keyExtract: [],
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'generalpregunta_3',
            key: 'nps-enc-general',
            keyExtract: [],
            extras: {
                omoikane: true
            }
        },
        /*{
            indicator: 'SURVEY_COUNT',
            key: 'survey-count-enc-general',
            keyExtract: [],
            grouped: 'mlCluster',
            extras: {
                omoikane: true,
                groupNameFormat: 'description',
                criticalMomentInclude: [1]
            }
        },
        {
            indicator: 'SURVEY_COUNT',
            key: 'survey-count-postventa',
            keyExtract: [],
            grouped: 'mlCluster',
            extras: {
                omoikane: true,
                groupNameFormat: 'description',
                criticalMomentInclude: [2]
            }
        },*/
    ],
    sentiment: {
        indicators: {
            General: 'sentiment-enc-general'
        },
        properties: {
            sentiment: 'sentimentGlobal',
            percentage: 'percentageGlobal',
            sentimentLocal: 'sentimentLocal',
            percentageLocal: 'percentageLocal'
        }
    },
    cxRay: {
        indicators: {
            General: [
                { order: 1, name: 'NPS', key: 'nps-enc-general', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-enc-general', widgetType: 'gauge' },
            ]
        }
    },
    clusters: {
        General: {
            counters: { indicator: 'survey-count-enc-general' },
            detail: {
                indicators: [
                    {
                        id: 'csat',
                        indicator: 'generalpregunta_1',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'nps',
                        indicator: 'generalpregunta_3',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'best-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'top',
                            criticalMomentInclude: [1]
                        },
                    },
                    {
                        id: 'worst-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'bottom',
                            criticalMomentInclude: [1]
                        },
                    },
                    {
                        id: 'last-comments',
                        indicator: 'COMMENTS',
                        keyExtract: [],
                        //grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [1]
                        }
                    },
                    {
                        id: 'alerts',
                        indicator: 'SURVEY_COUNT',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [1]
                        }
                    },
                    {
                        id: 'alerts-counters',
                        indicator: 'ALERT_COUNTERS',
                        keyExtract: [],
                        grouped: 'mlCluster,alertType',
                        extras: {
                            criticalMomentInclude: [1]
                        }
                    }
                ]
            }
        }
    },
    customerJourney: {
        General: (data: any) => {
            return data['GENERAL_CUSTOMER_JOURNEY']?.data.map((item: any, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item.group,
                weight: BENAVIDES_CJ_ORDER[item.groupId] ?? item.groupId,
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            }))
            // @ts-ignore
            .asMutable()
            .sort((item1: any, item2: any) => item1.weight - item2.weight )
        }
    }
}
