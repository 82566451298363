import useClientConfig from 'config/clients/useClientConfig'

const { getQuestionsCustomerName, getQuestionsOrder } = useClientConfig()

export const getResponseAnswer = (qa: any) => {
  if (qa.answerFormated) {
    return qa.answerFormated
  } else if (qa.questionType === 'NUMBER') {
    return qa.numberAnswer !== undefined
      ? qa.numberAnswer
      : qa.decimalAnswer
  } else if (qa.questionType === 'DECIMAL') {
    return qa.decimalAnswer
  } else if (qa.questionType === 'COMMENT') {
    return qa.commentAnswer
  } else if (qa.questionType === 'CHOICE' || qa.questionType === 'TEXT') {
    return qa.answerFormated ?? qa.textAnswer
  }

  return '-'
}


function toInitCap(text: string): string {
  return text?.toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const getCustomerName = (detail: any, defaultValue: string): string => {
  //si  tenemos info en firstname o lastname pude ser alerta o survey
  const customerName = detail?.customerName || '-';
  const customerLastname = detail?.customerLastname || '';
  const fullName = `${customerName} ${customerLastname}`.trim();

  if (fullName !== '-') {
    return toInitCap(fullName);
  }

  //si tenemos info a nivel de  pregunta en los casos de survey
  const questionCustomerNames = getQuestionsCustomerName();
  const qrCustomer = detail?.questionResponses?.find((item: any) => questionCustomerNames?.includes(item.questionName));
  if (qrCustomer && qrCustomer.answerFormated?.trim() !== '') {
    return toInitCap(qrCustomer.answerFormated)
  }

  //si tenemos info a nivel de human survey en los casos de alertas
  if (detail?.humanSurvey) {
    var humanSurvey
    try {
      humanSurvey = JSON.parse(detail?.humanSurvey);
    } catch (e) {
      console.error(e); // error in the above string (in this case, yes)!
    }

    for (const question of questionCustomerNames) {
      //@ts-ignore
      const hsCustomer = humanSurvey[question]
      if (hsCustomer && hsCustomer.formated) {
        return toInitCap(hsCustomer.formated)
      }
    };
  }

  return defaultValue;
}



export const calculateResponses = (detail: SurveyResponse) => {

  //console.log('Calculando survey detail')
  //console.log(detail)

  const responses = detail !== undefined
    && detail !== null
    && detail.questionResponses !== undefined
    && detail.questionResponses !== null
    ? detail.questionResponses
      .filter(qa => qa.questionType !== 'CALCULATED'
        && qa.questionName !== 'CALCULATED_SENTIMENT'
        && qa.questionName !== 'TAG_CONNECTION'
        && qa.questionText != null
        && qa.questionText != undefined)
    : []

  const questionOrder = getQuestionsOrder();

  const items = responses.reduce((acc: any, curr: QuestionResponse) => {

    const result = questionOrder?.find(item => item.questionName === curr.questionName);

    const questionId = result?.order ?? curr.questionId + 1000 //sumo 1000 parta evitar que el id se pise con los ordernamientos
    const currAcc = acc[questionId]
    const af = getResponseAnswer(curr)
    const multiple = currAcc !== undefined
      && currAcc.answerFormated !== undefined
      && !currAcc.answerFormated.includes(af)
    return {
      ...acc,
      [questionId]: {
        ...curr,
        answerFormated: multiple
          ? currAcc.answerFormated + ' | ' + af : af
      }
    }
  }, {})


  console.log("items");
  console.log(items);

  return Object.values(items)
}


