import { COLORS_CONFIG, DEFAULT_COLORS } from '@components/widgets/apacheEchart/Commons'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'


export const suzukiCustomPieMapper = (data: any) => {
  return data.map((item: any) => ({
    id: item["group"] == null ? "Indefinido" : item["group"],
    label: item["group"] == null ? "Indefinido" : item["group"],
    value: item["value"],
  }))
}

export const getCmID = (code: string): number => {
  if (code === 'venta') return 10651
  else if (code === 'servicio' || code === 'postventa') return 10652
  else return 1
}

export const SUZUKI_COLORS_CONFIG = [
  {
    color: DEFAULT_COLORS[1],
    condition: '<=' as const,
    key: 'value',
    value: 3,
  },
  {
    color: DEFAULT_COLORS[2],
    condition: '>' as const,
    key: 'value',
    value: 3,
  },
  {
    color: DEFAULT_COLORS[0],
    condition: '>=' as const,
    key: 'value',
    value: 4,
  },
]

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  multipleGaugeByQuestion: {
    ...widgetsLibrary.multipleGaugeByQuestion,
    //@ts-ignore
    config: {
      ...widgetsLibrary.multipleGaugeByQuestion.config,
      colors: SUZUKI_COLORS_CONFIG,
      maxValue: 5
    },
  }
}
