import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/omoikane-ai',
  layout: true,
  getComponent: async () => {
    const OmoikaneAiLayout = await import('./components/OmoikaneAiLayout')
    const { reducer, rootSagas, } = await import('./OmoikaneAiModule')

    injectReducer(store, { key: 'omoikaneAi', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: OmoikaneAiLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'Omoikane AI', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const OmoikaneAiView = await import('./components/OmoikaneAiView')
        return { children: OmoikaneAiView.default, }
      },
    },
  
  ],
})

