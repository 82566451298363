
import logoMitsubishiSmall from '@assets/logos/mitsubishi-logo-small.png'
import logoMitsubishi from '@assets/logos/mitsubishi-logo.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getMitsubishiFilterCriticalMomentDefault, getMitsubishiFilterCriticalMoments } from '../dashboard/dashHelper'
import { omoikaneMitsubishi } from '../omoikane/omoikaneMitsubishi'
import deafultHelpDefinition from '../help/defaultHelp'

const epsilonBack = true

export const mitsubishiConfigs: ClientConfigInfo = {
    vocBackendUrl: epsilonBack
        ? 'https://mitsubishi-backend-voc.limetropy.com/api'
        : 'https://mitsubishi.limetropy.com/api/',
    lcmBackendUrl: '',
    //vocBackendUrl: 'http://localhost:9000/api/',
    publicUrl: undefined,
    logoSmall: logoMitsubishiSmall,
    logo: logoMitsubishi,
    filtersFromDateSubstract: 2,
    filtersToDateSubstract: 1,
    decimals: 1,
    supportsInternationalization: true,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.GEOS, FILTER_TYPES.CRITICAL_MOMENTS],
    menuDashboards: [DASHBOARDS.DEFAULT],
    msExcelReportExt: 'xls',
    geosRootId: 10001,
    logicalsRootId: 0,
    criticalMomentsRootId: 0,
    criticalMomentFilterType: 'select',
    actionPlans: {
        geoLeafLevel: '',
        useWorstTags: true
    },
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    omoikane: omoikaneMitsubishi,
    getFilterCriticalMoments: getMitsubishiFilterCriticalMoments,
    getFilterCriticalMomentDefault: getMitsubishiFilterCriticalMomentDefault,
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.mitsubishi,
        default: DASHBOARDS_CONFIGS.mitsubishi[DASHBOARDS.DEFAULT]
    },
    useDefaultCriticalMoment: true,
    staticDashboards: {},
    surveyResponses: {
        url: 'survey-responses',
        type: epsilonBack ? 'post' : 'get',
        tableColumns: undefined,
        tabs: [SurveyResponsesTab.ANSWERED/*, SurveyResponsesTab.NOT_ANSWERED*/],
    },
    alertDetailFields: [],
    help: deafultHelpDefinition
}
