

export const widgetsRanking: CommonWidget = {


  /**************************************************************************
   *                                RANKINGS
  ***************************************************************************/

  rankingByGeoBest: {
    title: 'Top 5 Mejores estaciones',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-by-geo-best',
      type: 'ranking',
      rankingType: 'box',
      showSample: false,
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'value', 'count'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'desc' },
          limit: 6,
          extras: {
            featureExclude: ['groupByTopLocation']
          },
        },
      ],
    },
  },

  rankingByGeoBestAndTopLocation: {
    title: 'Top 5 Mejores estaciones',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-by-geo-best',
      type: 'ranking',
      rankingType: 'box',
      showSample: false,
      indicators: [
        {
          indicator: 'satisfaction',
          keyExtract: ['group', 'value', 'count'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'desc' },
          limit: 6,
          extras: {},
        },
      ],
    },
  },

  rankingByGeoWorst: {
    title: 'Top 5 Estaciones a mejorar',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-by-geo-worst',
      type: 'ranking',
      rankingType: 'box',
      showSample: false,
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'value', 'count'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'asc' },
          limit: 6,
          extras: {
            featureExclude: ['groupByTopLocation']
          },
        },
      ],
    },
  },

  rankingByGeoWorstAndTopLocation: {
    title: 'Top 5 Estaciones a mejorar',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-by-geo-worst',
      type: 'ranking',
      rankingType: 'box',
      showSample: false,
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'value', 'count'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'asc' },
          limit: 6,
          extras: {},
        },
      ],
    },
  },

  rankingByTagBest: {
    title: 'Sentimiento positivo con más frecuencia',
    position: [0, 18],
    size: [2, 1],
    config: {
      id: 'ranking-by-tag-best',
      type: 'ranking',
      color: '#019E85',
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['groupName', 'positive', 'count'],
          label: 'groupName',
          //'value': 'positive',
          singleton: true,
          grouped: 'tag',
          sort: { value: 'desc' },
          limit: 1,
          extras: {
            resultType: 'top'
          },
        },
      ],
    },
  },

  rankingByTagWorst: {
    title: 'Sentimiento negativo con más frecuencia',
    position: [2, 18],
    size: [2, 1],
    config: {
      id: 'ranking-by-tag-worst',
      type: 'ranking',
      color: '#DB7476',
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['groupName', 'positive', 'count'],
          label: 'groupName',
          //'value': 'positive',
          singleton: true,
          grouped: 'tag',
          sort: { sortBy: 'positive', value: 'desc' },
          limit: 1,
          extras: {
            resultType: 'bottom'
          },
        },
      ],
    },
  },

  rankingByQuestionBest: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-by-question-best',
      type: 'ranking',
      fontSize: '30px',
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'question',
          sort: { value: 'desc' },
          limit: 3,
          extras: {
            featureExclude: ['groupByTopLocation'],
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  rankingByQuestionWorst: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-by-question-worst',
      type: 'ranking',
      fontSize: '30px',
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'question',
          sort: { value: 'asc' },
          limit: 3,
          extras: {
            featureExclude: ['groupByTopLocation'],
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },

  rankingPositionHighestScope: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-position-highest-scope',
      type: 'ranking',
      rankingType: 'comparative',
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'position', 'groupCount'],
          grouped: 'geoLocation',
          label: 'group',
          value: 'position',
          singleton: true,
          sort: { value: 'desc' },
          //limit: 1,
          extras: {
            aggregation: 'rankingPosition',
            rankingPositionScope: 0,
            completeGroups: true
          },
        },
      ],
    },
  },
  rankingPositionLowestScope: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-position-lowest-scope',
      type: 'ranking',
      rankingType: 'comparative',
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'position', 'groupCount'],
          grouped: 'geoLocation',
          label: 'group',
          value: 'position',
          singleton: true,
          sort: { value: 'desc' },
          limit: 1,
          extras: {
            aggregation: 'rankingPosition',
            rankingPositionScope: 100,
            completeGroups: true
          },
        },
      ],
    }
  }
}
