import { getCommonWidget } from "../dashWidgetHelper"
import {  bupaStakedBarDetailedByMonthMapper, commonWidgets } from "./commonWidgetsBupa"



const getBupaWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => [
  
  getCommonWidget({
    commonWidgets: commonWidgets,
    //name: 'barDetailedByMonthAndGeo',
    name: 'barByMonth',
    title: 'NPS General por mes',
    position: [0, 0],
    size: [4, 3],
    // @ts-ignore
    mapper: bupaStakedBarDetailedByMonthMapper,
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
      titleAlign: 'center'
    },
    indicator: 'NPS',
    //render: (filters: FiltersUI) => renderForCriticalMoment(filters, [29]),
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'surveyCountTrendByMonth',
    position: [0, 3],
    size: [1, 2],
    indicator: 'survey-count'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'surveyCountTrendByWeek',
    position: [1, 3],
    size: [1, 2],
    indicator: 'survey-count'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'alertsMultipleTrendByMonth',
    subTitle: 'Respuesta alertas mensual',
    position: [2, 3],
    size: [1, 2],
    indicator: 'alert-first-response-time'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'alertsMultipleTrendByWeek',
    subTitle: 'Respuesta alertas semanal',
    position: [3, 3],
    size: [1, 2],
    indicator: 'alert-first-response-time'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    position: [0, 5],
    size: [4, 4],
    indicator: 'SENTIMENT'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'diagramaIshikawa',
    title: 'widgets.ishikawa',
    position: [0, 9],
    size: [4, 2],
    indicator: 'SENTIMENT'
  }),
  /*getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCountWithLinks',
    title: 'Nube de Palabras / Frecuencia en comentarios',
    position: [0, 11],
    size: [4, 2],
    extraConfigs: {
      tagsLimit: 50
    }
  }),*/
]

export default {
  title: 'Bupa Dashboard',
  description: 'Dashboard de Bupa',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI, filtersData: Record<string, any>) => getBupaWidgets(filters, filtersData),
} as DasboardConfig
