
import { ClientConfigInfo } from './configCommons'
import { mitsubishiConfigs } from './mitsubishi'
import { oxxoConfigs } from './oxxo'

export const localConfigs: ClientConfigInfo = {
    // ByD
    //vocBackendUrl: 'https://byd-backend-voc.limetropy.com/api/',
    // H. Angeles
    //vocBackendUrl: 'https://ha-backend-voc.limetropy.com/api/',
    // Suzuki
    //vocBackendUrl: 'https://suzuki-backend.limetropy.com/api/',
    // Caffenio
    //vocBackendUrl: 'https://caffenio-backend-voc.limetropy.com/api/',
    //lcmBackendUrl: 'https://caffenio-backend-core.limetropy.com/backend/core/api/ui/',
    // OXXO
    //...oxxoConfigs,
    //vocBackendUrl: 'https://oxxogas.limetropy.com/api/',
    //lcmBackendUrl: 'https://oxxogas.limetropy.com/api/',
    // Mitsubishi
    ...mitsubishiConfigs,
    //vocBackendUrl: 'https://mitsubishi.limetropy.com/api/',
    // Local
    vocBackendUrl: 'http://localhost:9000/api/',
    //lcmBackendUrl: 'http://localhost:9000/api/',
    lcmBackendUrl: '',
}
