import { all } from 'redux-saga/effects'
import { Saga } from 'redux-saga'
import modules from '@modules'

const root = function* root(): Generator {
  yield all(modules.sagas)
}

/**
 * Inject a saga.
 * @param {Object} store: Global store of the app.
 * @param {Array}  sagas: List of saga functions to inject.
 */
export function injectSagas(store: Store, sagas: Saga[]): void {
  // TODO: Add check to validate that sagas is valid (is an array of generators)
  sagas.map(store.runSaga, store)
}

export default root
