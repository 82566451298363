/**
 * Helper function to convert the filter object in a FIQL string
 * NOTE: This function for now only supports AND and IN operators.
 * @param {Object} filters
 * @returns {String}
 */
export default (filters: object): string => {
  const query: string[] = []
  const keys = Object.keys(filters)

  keys.forEach((key) => {
    query.push(`${key} eq ${filters[key]}`)
  })
  return query.join(' and ')
}