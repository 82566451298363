import { COLORS_CONFIG } from '@components/widgets/apacheEchart/Commons'
import { answeredMapper, invalidMapper } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from './commonWidgetsMitsu'
export default {
  title: 'Distribuidor Dashboard',
  description: 'Dashboard de Distribuidor',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snVenta',
      position: [0, 0],
      size: [1, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsVenta',
      position: [1, 0],
      size: [1, 2],
      indicator: 'POSVENTA_NPS',
      criticalMomentId: 1,
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosVenta',
      position: [2, 0],
      size: [1, 2],
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper,
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      position: [3, 0],
      size: [1, 2],
      indicator: 'venta-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      position: [0, 2],
      size: [4, 2],
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label:  'widgets.indicatorId.' + item.groupId
          }
        })
      },
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasVenta',
      position: [0, 4],
      size: [2, 2],
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: 1,
      extras: {
        featureExclude: ['groupByTopLocation'],
        groupNameFormat: 'description',
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasVenta',
      position: [2, 4],
      size: [2, 2],
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: 1,
      extras: {
        featureExclude: ['groupByTopLocation'],
        groupNameFormat: 'description',
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsVenta',
      position: [0, 6],
      size: [2, 2],
      indicator: 'SENTIMENT',
      criticalMomentId: 1,
      extras: {
        resultType: 'top',
        trunc: "HALF_DOWN",
        topSize: 3
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsVenta',
      position: [2, 6],
      size: [2, 2],
      indicator: 'SENTIMENT',
      criticalMomentId: 1,
      extras: {
        resultType: 'bottom',
        trunc: "HALF_UP",
        bottomSize: 3
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'widgets.sentimentAnalysis',
      position: [0, 8],
      size: [4, 4],
      criticalMomentId: 1,
    }),
    {
      title: 'widgets.rankingZoneDistribuidores',
      position: [0, 12],
      size: [2, 1],
      config: {
        id: 'venta-ranking-zonas',
        type: 'rankingIPN',
        /*mapper: (data: any) => {
          return data.filter((item: any) => item.id == 1)
        },*/
        indicators: [
          {
            indicator: 'VENTA_SATISFACCION',
            keyExtract: ['group', 'position', 'groupCount'],
            grouped: 'geoLocation',
            label: 'group',
            value: 'position',
            singleton: true,
            sort: { value: 'desc' },
            //limit: 1,
            extras: {
              aggregation: 'rankingPosition',
              rankingPositionScope: 0,
              //featureInclude: ['zoomOut'],
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.rankingNationalDistribuidores',
      position: [2, 12],
      size: [2, 1],
      config: {
        id: 'venta-ranking-nacional',
        type: 'rankingIPN',
        indicators: [
          {
            indicator: 'VENTA_SATISFACCION',
            keyExtract: ['group', 'position', 'groupCount'],
            grouped: 'geoLocation',
            label: 'group',
            value: 'position',
            singleton: true,
            sort: { value: 'desc' },
            limit: 1,
            extras: {
              aggregation: 'rankingPosition',
              rankingPositionScope: 100,
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.driveTestReasonsVenta',
      // [Column, Row]
      position: [0, 13],
      size: [2, 2],
      config: {
        id: 'venta-no-prueba',
        type: 'pie',
        fillMode: '',
        colors: ['#015c52', '#33b9ce', '#f38625'],
        showValues: true,
        //percentageScale: false,
        mapper: (data) => {
          return data.map((item) => ({
            id: item['group'] == null ? 'Indefinido' : item['group'],
            label: item['group'] == null ? 'Indefinido' : item['group'],
            value: item['value'],
          }))
        },
        indicators: [
          {
            indicator: 'VENTA_SIN_PRUEBA_MANEJO_MOTIVO',
            //grouped: 'geoLocation',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.financingReasonsVenta',
      // [Column, Row]
      position: [2, 13],
      size: [2, 2],
      config: {
        id: 'venta-no-financiacion',
        type: 'pie',
        fillMode: '',
        colors: ['#015c52', '#33b9ce', '#f38625'],
        showValues: true,
        mapper: (data) => {
          return data.map((item) => ({
            id: item['group'] == null ? 'Indefinido' : item['group'],
            label: item['group'] == null ? 'Indefinido' : item['group'],
            value: item['value'],
          }))
        },
        indicators: [
          {
            indicator: 'VENTA_SIN_FINANCIAMIENTO_MOTIVO',
            //grouped: 'geoLocation',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.otherIndicators',
      type: 'label',
      position: [0, 15],
      size: [4, 1],
    },
    {
      title: 'widgets.instalations',
      // [Column, Row]
      position: [0, 16],
      size: [1, 2],
      config: {
        id: 'venta-instalaciones',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'VENTA_INSTALACIONES',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.parkingVenta',
      // [Column, Row]
      position: [1, 16],
      size: [1, 2],
      config: {
        id: 'venta-estacionamiento',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'VENTA_ESTACIONAMIENTO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.upsellingVenta',
      // [Column, Row]
      position: [2, 16],
      size: [1, 2],
      config: {
        id: 'venta-accesorios-adicionales',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'VENTA_ACCESORIOS_ADICIONALES',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.carStatusVenta',
      // [Column, Row]
      position: [3, 16],
      size: [1, 2],
      config: {
        id: 'venta-estado-vehiculo',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'VENTA_ESTADO_VEHICULO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.warrantyExplanation',
      // [Column, Row]
      position: [0, 18],
      size: [1, 2],
      config: {
        id: 'venta-garantia',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'VENTA_GARANTIA',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
  ],
} as DasboardConfig
