import React, { useEffect, useState, memo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useActions from '@hooks/useActions'
import modules from '@modules'
import { Store } from 'redux'
import { useStore } from 'react-redux'
import { History, Location } from 'history'
import { Spin } from 'antd'

type PropsType = {
  component?: React.ElementType
  getComponent?: () => Promise<{ children?: React.FC | React.ElementType }>
  onEnter?: (store: Store, history: History<any>, location: Location) => void
  title?: string
}

function useComponentLoader(
  getComponent?: () => Promise<{ children?: React.FC | React.ElementType }>,
  component?: React.ElementType,
  onEnter?: (store: Store, history: History, location: Location<any>) => void,
  title?: string,
): Array<{ children?: React.FC | React.ElementType }> {
  const dispatcher = useActions(modules.actions.appData)
  const history = useHistory()
  const store = useStore()
  const location = useLocation<any>()

  const [components, setComponents,] = useState<{
    children?: React.FC | React.ElementType
  }>({
    children: component,
  })

  useEffect(() => {
    const loadComponent = async () => {
      if (getComponent) {
        const asyncModules = await getComponent()
        if (onEnter) {
          onEnter(store, history, location)
        }
        setComponents(asyncModules)
      } else if (onEnter) {
        onEnter(store, history, location)
      }
    }
    loadComponent()

    if (title) {
      dispatcher.setTitle({ title, })
    }
  }, [])

  return [components,]
}

const LoadableComponent = memo((props: PropsType): JSX.Element => {
  const { component, getComponent, onEnter, title, ...rest } = props
  const [components,] = useComponentLoader(getComponent, component, onEnter, title)

  return (
    <>
      {
        components?.children ? 
          <components.children title {...rest} />:
          <div className="loading-container"><Spin/></div>
      }
    </>
  )
})

LoadableComponent.displayName = 'LoadableComponent'

export default LoadableComponent
