
import logoBupa from '@assets/logos/bupa-logo.png'
import logoBupaSmall from '@assets/logos/bupa-logo-small.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments } from '../dashboard/dashHelper'
import deafultHelpDefinition from '../help/defaultHelp'

export const bupaConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://bupa-backend-voc.limetropy.com/api/',
    lcmBackendUrl: 'https://bupa-backend-core.limetropy.com/api/ui/',
    //vocBackendUrl: 'http://localhost:9000/api/',
    publicUrl: '/voc',
    logoSmall: logoBupaSmall,
    logo: logoBupa,
    filtersFromDateSubstract: 1,
    filtersToDateSubstract: 0,
    filters: [
        FILTER_TYPES.DATES, 
        FILTER_TYPES.GEOS, 
        FILTER_TYPES.LOGICALS, 
        FILTER_TYPES.CRITICAL_MOMENTS, 
        FILTER_TYPES.DYNAMIC
    ],
    supportsInternationalization: false,
    msExcelReportExt: 'xlsx',
    decimals: 0,
    menuDashboards: [DASHBOARDS.DEFAULT],
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    geosRootId: 17101,
    logicalsRootId: 0,
    criticalMomentsRootId: 17151,
    criticalMomentFilterType: 'tree',
    actionPlans: {
        geoLeafLevel: '',
        useWorstTags: true
    },
    omoikane: {
        decimals: 2,
        enabledTabs: [],
        monthdsCoverage: 2,
        indicators: [],
        sentiment: {
            indicators: {},
            properties: {
                sentiment: '',
                percentage: '',
                sentimentLocal: '',
                percentageLocal: ''     
            }
        },
        customerJourney: {},
        cxRay: { indicators: {} },
        clusters: {},
    },
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.LOGICALS,
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS,
        //METADADATA_LOAD.WORKFLOWS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.bupa,
        default: DASHBOARDS_CONFIGS.bupa[DASHBOARDS.DEFAULT]
    },
    staticDashboards: {},
    surveyResponses: {
        url: 'survey-responses',
        type: 'post',
        tableColumns: undefined,
        tabs: [SurveyResponsesTab.ANSWERED]
    },
    alertDetailFields: [],
    notificationsConfig: {
        enabled: true
    },
    help: deafultHelpDefinition
}
