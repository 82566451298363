import { getCommonWidget } from '../dashWidgetHelper'
import { getLineDetailedRadar } from '../widgetsLibrary/widgetsLine'
import { commonWidgets } from './commonWidgetsCaffenio'

export default {
  title: 'General Dashboard',
  description: 'General de perfil Default',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    getLineDetailedRadar({
      mainIndicator: 'satisfaccion_pregunta_3',
      radialIndicators: [
        'satisfaccion_pregunta_3',
        'satisfaccion_pregunta_7',
        'satisfaccion_pregunta_6',
        'alert-counters'
      ],
      radialType: 'byIndicator',
      position: [0, 0],
      size: [4, 3],
      title: 'Satisfacción General',
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
        showRadialChart: true,
        initialVisibleChart: 1,
      },
      criticalMomentInclude: [10601],
      indicatorsNames: {
        'satisfaccion_pregunta_3': 'Satisfacción',
        'satisfaccion_pregunta_7': 'Disposición a regresar',
        'satisfaccion_pregunta_6': 'NPS',
        'alert-counters-wow': '% Alertas WOW',
        'alert-counters-rescue': '% Alertas rescate',
      },
    }),

    //2
    {
      title: 'Encuestas',
      type: 'label',
      position: [0, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    {
      title: 'Alertas',
      type: 'label',
      position: [2, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'survey-count',
      criticalMomentInclude: [10601],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'survey-count',
      criticalMomentInclude: [10601],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByMonth',
      subTitle: 'Respuesta alertas mensual',
      position: [2, 4],
      size: [1, 2],
      indicator: 'alert-first-response-time',
      criticalMomentId: 10601,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByWeek',
      subTitle: 'Respuesta alertas semanal',
      position: [3, 4],
      size: [1, 2],
      indicator: 'alert-first-response-time',
      criticalMomentId: 10601,
    }),

    //3
    {
      title: 'Análisis de sentimientos',
      type: 'label',
      position: [0, 6],
      size: [4, 1]
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      position: [0, 7],
      size: [4, 4],
      criticalMomentInclude: [0, 10601],
    }),
    /*getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyResponsesTrend',
      position: [0, 4],
      size: [1, 2],
      criticalMomentInclude: [0, 29]
    }),*/
    //4
    {
      title: 'Sucursales',
      type: 'label',
      position: [0, 11],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa de Sucursales',
      position: [0, 12],
      size: [2, 2],
      indicator: 'survey-count',
      extraConfigs: {
        bestLocationValueFrom: 90,
        worstLocationValueTo: 70
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'Top 5 Mejores Sucursales',
      position: [2, 12],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
      },
      indicator: 'satisfaccion_pregunta_3',
      criticalMomentInclude: [10601],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'Top 5 Sucursales a mejorar',
      position: [3, 12],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
      },
      indicator: 'satisfaccion_pregunta_3',
      criticalMomentInclude: [10601],
    }),

    //5
    {
      title: 'Diagrama de Ishikawa',
      type: 'label',
      position: [0, 14],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      //title: 'Diagrama Ishikawa',
      position: [0, 15],
      size: [4, 2],
      indicator: 'sentiment',
      criticalMomentInclude: [10601],
    }),

    //6
    {
      title: 'Palabras',
      type: 'label',
      position: [0, 17],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 18],
      size: [4, 4],
      criticalMomentInclude: [0, 10601],
      extraConfigs: {
        symbolSizeMultiplier: 1.5,
        forceGravity: 0.5
      }
    }),
  ],
} as DasboardConfig
