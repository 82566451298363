import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import config from '@config'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(config.i18n)

export default i18n
