import {
    PRODUTURO_PIE_COLORS, PRODUTURO_PIE_SINO_COLORS, commonWidgets, getProfuturoAtencion, getProfuturoFacilidad,
    getProfuturoNpsAcumulado, getProfuturoNpsPorMes, getProfuturoSatisfaccion, getProfuturoNpsConsar
} from "../commonWidgetsProfuturo";
import { getCommonWidget } from '../../dashWidgetHelper'
import {renderForCriticalMoment}from '../../dashHelper'

import Color from 'color'

const renderConAsesor = (filters : FiltersUI, defecto: boolean) : boolean =>{
    console.log ('renderConAsesor')
    console.log (filters)
    return renderAsesores(filters, 'CON ASESOR', defecto) && renderForCriticalMoment(filters, [11451])

}

const renderSinAsesor = (filters : FiltersUI, defecto: boolean) : boolean =>{
    return renderAsesores(filters, 'SIN ASESOR', defecto) && renderForCriticalMoment(filters, [11451])    
}

const renderAsesores = (filters : FiltersUI, tipo :String, defecto: boolean) : boolean =>{
    
    if (filters?.responseValues?.[173003200]?.[0]){
        return filters?.responseValues?.[173003200]?.[0] === tipo;    
    } 
    return defecto;
    
    
}

export default {
    title: 'Autoafiliacion Dashboard',
    description: 'Autoafiliacion Dashboard',
    backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
    widgets: (filters: FiltersUI) => [
        {
            title: 'Principales indicadores',
            position: [0, 0],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getProfuturoNpsAcumulado({
            position: [0, 1],
            size: [1, 2],
            indicator: 'AUTOSERVICIO_NPS'
        }),
        getProfuturoNpsPorMes({
            position: [1, 1],
            size: [1, 2],
            title: 'NPS Acumulado',
            indicator: 'AUTOSERVICIO_NPS',
            helpText: 'A partir de tu experiencia con el trámite para convertirte en cliente de Profuturo, en una escala de 0 a 10, ¿qué tan probable es que recomiendes a Profuturo con un amigo o familiar?'
        }),
        /*getProfuturoNpsPorMes({
            position: [2, 1],
            size: [1, 2],
            title: 'NPS sin Asesor',
            indicator: 'autoserv_sin_asesor_pregunta_21',
            helpText: 'A partir de tu experiencia con tu trámite para convertirte en cliente de Profuturo a través de los canales digitales, en una escala de 0 a 10, ¿qué tan probable es que recomiendes a Profuturo con un amigo o '
        }),
        */
        getProfuturoFacilidad({
            position: [2, 1],
            size: [1, 2],
            indicator: 'AUTOSERVICIO_FACILIDAD',
            helpText: 'Pensando en tu trámite para convertirte en cliente Profuturo a través de los canales digitales, ¿qué tan fácil te resultó?',
        }),
         getProfuturoSatisfaccion({
            position: [0, 3],
            size: [1, 2],
            indicator: 'autoserv_con_asesor_pregunta_9',
            helpText: '¿Qué tan satisfecho estás con la información recibida previa al trámite?'
        }),
        getProfuturoAtencion({
            position: [1, 3],
            size: [1, 2],
            indicator: 'autoserv_con_asesor_pregunta_3',
            helpText: 'Hablemos de tu experiencia con la atención recibida, ¿cómo fue?'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericLarge',
            title: 'Motivadores en facilidad - Sin Asesor',
            position: [0, 5],
            size: [2, 2],
            helpText: '¿Por qué te resultó fácil? ',
            indicator: 'autoserv_sin_asesor_pregunta_2',//'AUTOSERVICIO_MOTIVADORES',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            render: (filters: FiltersUI) => {
                return renderSinAsesor(filters,true)

            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericLarge',
            title: 'Motivadores en facilidad - Con Asesor',
            position: [0, 5],
            size: [2, 2],
            helpText: '¿Por qué te resultó fácil? ',
            indicator: 'autoserv_con_asesor_pregunta_6',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            render: (filters: FiltersUI) => {
               return renderConAsesor(filters,false)
           },
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericLarge',
            title: 'Quiebres en facilidad - Sin Asesor',
            position: [2, 5],
            size: [2, 2],
            helpText: '¿Qué podemos hacer para facilitar el trámite a través de los canales digitales?',
            indicator: 'autoserv_sin_asesor_pregunta_4',//'AUTOSERVICIO_QUIEBRES',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            render: (filters: FiltersUI) => {
                return renderSinAsesor(filters, true)

            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericLarge',
            title: 'Quiebres en facilidad - Con Asesor ',
            position: [2, 5],
            size: [2, 2],
            helpText: '¿Qué podemos hacer para facilitar el trámite a través de los canales digitales?',
            indicator: 'autoserv_con_asesor_pregunta_7',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            render: (filters: FiltersUI) => {
                return renderConAsesor(filters, false)

            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericLarge',
            title: 'Atención: Clientes Insatisfechos',
            position: [0, 7],
            size: [2, 2],
            helpText: ' ¿En qué podemos mejorar?',
            indicator: 'autoserv_con_asesor_pregunta_4',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericLarge',
            title: 'Tiempo estimado para completar el trámite',
            position: [2, 7],
            size: [2, 2],
            helpText: '¿Cuánto tiempo estimas que llevó completar tu trámite? Por favor, señala una de las opciones.',
            indicator: 'autoserv_sin_asesor_pregunta_16',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCount',
            title: 'Dificultad en la toma de selfie',
            position: [0, 9],
            size: [2, 2],
            helpText: '¿Por qué no te resultó fácil la toma de tu selfie en el trámite para convertirte en cliente Profuturo?',
            indicator: 'autoservicio_pregunta_42'
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCount',
            title: 'Momento decisivo',
            position: [2, 9],
            size: [2, 2],
            helpText: '¿Qué estabas haciendo cuando decidiste iniciar el trámite de registro/cambio a Profuturo?',
            indicator: 'autoserv_sin_asesor_pregunta_22'
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCount',
            title: 'Motivador para ser cliente',
            position: [0, 11],
            size: [2, 2],
            helpText: '¿Qué te motivó a cambiarte a Profuturo?',
            indicator: 'autoserv_sin_asesor_pregunta_23'
        }),

        {
            title: 'Atributos de la experiencia grado de facilidad',
            position: [0, 13],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La descarga de la app en tu celular',
            position: [0, 14],
            size: [4, 1],
            helpText: 'Pensando en las distintas etapas del trámite, ¿qué tan fácil te resultó cada una? ',
            indicator: 'autoserv_sin_asesor_pregunta_7'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'El ingreso y validación de tu CURP y datos de contacto',
            position: [0, 15],
            size: [4, 1],
            helpText: 'Pensando en las distintas etapas del trámite, ¿qué tan fácil te resultó cada una? ',
            indicator: 'autoserv_sin_asesor_pregunta_8'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La recepción y registro del código de verificación',
            position: [0, 16],
            size: [4, 1],
            helpText: 'Pensando en las distintas etapas del trámite, ¿qué tan fácil te resultó cada una? ',
            indicator: 'autoserv_sin_asesor_pregunta_9'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La lectura de la información comparativa de las Afores',
            position: [0, 17],
            size: [4, 1],
            helpText: 'Pensando en las distintas etapas del trámite, ¿qué tan fácil te resultó cada una? ',
            indicator: 'autoserv_sin_asesor_pregunta_10'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La toma de tu selfie',
            position: [0, 18],
            size: [4, 1],
            helpText: 'Pensando en las distintas etapas del trámite, ¿qué tan fácil te resultó cada una? ',
            indicator: 'autoserv_sin_asesor_pregunta_11'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La captura de tu INE/IFE o pasaporte',
            position: [0, 19],
            size: [4, 1],
            helpText: 'Pensando en las distintas etapas del trámite, ¿qué tan fácil te resultó cada una? ',
            indicator: 'autoserv_sin_asesor_pregunta_12'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'El registro de tu información personal',
            position: [0, 20],
            size: [4, 1],
            helpText: 'Pensando en las distintas etapas del trámite, ¿qué tan fácil te resultó cada una? ',
            indicator: 'autoserv_sin_asesor_pregunta_13'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La designación de beneficiarios',
            position: [0, 21],
            size: [4, 1],
            helpText: 'Pensando en las distintas etapas del trámite, ¿qué tan fácil te resultó cada una? ',
            indicator: 'autoserv_sin_asesor_pregunta_14'
        }),
        {
            title: 'Atributos de la experiencia grado de acuerdo',
            position: [0, 22],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'Me sentí seguro al compartir en el canal digital mi información y datos durante el trámite',
            position: [0, 23],
            size: [4, 1],
            helpText: 'Pensando en tu trámite para convertirte en cliente de Profuturo a través de los canales digitales, por favor señala que tan de acuerdo estás con cada una de las frases',
            indicator: 'autoserv_sin_asesor_pregunta_18'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'En caso de dudas, sé a dónde escribir/llamar para que me ayuden a resolverlas.',
            position: [0, 24],
            size: [4, 1],
            helpText: 'Pensando en tu trámite para convertirte en cliente de Profuturo a través de los canales digitales, por favor señala que tan de acuerdo estás con cada una de las frases',
            indicator: 'autoserv_sin_asesor_pregunta_19'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'Entendí cada parte del proceso. Las instrucciones fueron claras y precisas.',
            position: [0, 25],
            size: [4, 1],
            helpText: 'Pensando en tu trámite para convertirte en cliente de Profuturo a través de los canales digitales, por favor señala que tan de acuerdo estás con cada una de las frases',
            indicator: 'autoserv_sin_asesor_pregunta_20'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'Conozco claramente los beneficios del ahorro voluntario',
            position: [0, 26],
            size: [4, 1],
            helpText: '¿Qué tan de acuerdo te encuentras con cada una de las siguientes afirmaciones? ',
            indicator: 'autoserv_con_asesor_pregunta_11'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'Se mostró compromiso y predisposición para responder mis dudas y consultas',
            position: [0, 27],
            size: [4, 1],
            helpText: '¿Qué tan de acuerdo te encuentras con cada una de las siguientes afirmaciones? ',
            indicator: 'autoserv_con_asesor_pregunta_12'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'Me ofrecieron o realizaron un estudio personalizado para el retiro',
            position: [0, 28],
            size: [4, 1],
            helpText: '¿Qué tan de acuerdo te encuentras con cada una de las siguientes afirmaciones? ',
            indicator: 'autoserv_con_asesor_pregunta_13'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'textAnalysis',
            title: 'Análisis de Sentimientos',
            position: [0, 29],
            size: [4, 4],
            indicator: 'SENTIMENT',

        }),

    ]
} as DasboardConfig