import { all, cancel, take} from 'redux-saga/effects'
import { LOCATION_CHANGE, LocationChangePayload } from 'connected-react-router'
import { Saga } from 'redux-saga'

interface LocationChangeAction {
  type: string
  payload: LocationChangePayload
}

/**
 * Active the watchers and listen for location change to cancel watchers base
 * on the domain of the resource.
 * @param sagas 
 * @param sagasDomain 
 */
export default function sagasRunner(sagas: Array<Saga>, sagasDomain: string) : any  {
  const saga = function* root() {
    // @ts-ignore
    const watchers = yield all(sagas)
    const data : { payload: LocationChangePayload } = yield take((action: any | LocationChangeAction) => {
      return action.type === LOCATION_CHANGE && action.payload.location.pathname.indexOf(sagasDomain) === -1
    })

    if (data.payload.location.pathname.indexOf(sagasDomain) === -1) {
      yield cancel(watchers)
    }
  }
  
  return [saga,]
}