import React, { useState } from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'

import './LanguageSwitcher.less'


const LanguageSwitcher = (): JSX.Element => {
    const { i18n } = useTranslation()
    const [realod, setRealod,] = useState<boolean>(false)

    const onChangeLanguage = (newLanguage: string) => {
        i18n.changeLanguage(newLanguage)
        setRealod(true)
    }

    return (
        <div className='language-switcher'>
            <Button
                className="language-button"
                key="language-en"
                //icon={<Icon type='Notification' theme='outlined' />}
                icon={<ReactCountryFlag svg countryCode="US"
                    style={{ width: '2em', height: '2em', }} />}
                //type='link'
                onClick={() => onChangeLanguage('en')}
            />
            <Button
                className="language-button"
                key="language-es"
                icon={<ReactCountryFlag svg countryCode="MX"
                    style={{ width: '2em', height: '2em', }} />}
                //type='link'
                onClick={() => onChangeLanguage('es')}
            />
        </div>
    )
}

export default LanguageSwitcher
