import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'
import { CLIENT_ACCOUNTS_MODULE_NAME } from './ClientAccountsModule'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/client-accounts',
  layout: true,
  getComponent: async () => {
    const ClientClientAccountsAdminLayout = await import(
      /* webpackChunkName: "surveys-modules" */ './components/ClientAccountsLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "surveys-modules" */ './ClientAccountsModule'
    )

    injectReducer(store, { key: CLIENT_ACCOUNTS_MODULE_NAME, reducer, })
    injectSagas(store, rootSagas)

    return {
      children: ClientClientAccountsAdminLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'surveys.list_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const ClientAccountsTableView = await import('../ClientAccounts/components/ClientAccountsTableView')
        return { children: ClientAccountsTableView.default }
      },
    },

    /*{
      path: '/detail/:id/',
      title: 'surveys.detail_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const ClientAccountDetailView = await import('./components/ClientAccountDetailView')
        return { children: ClientAccountDetailView.default, }
      },
    },*/
  ],
})

