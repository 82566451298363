export const SECTION_KEYS = {
  DASHBOARD: 'dashboard',
  EXTENDED_DASHBOARD: 'extended-dashboard',
  //DASHBOARD_DISEL: 'dashboard-disel',
  //DASHBOARD_QR: 'dashboard-qr',
  //DASHBOARD_BUILDER: 'dashboard-builder',
  //DASHBOARD_BANIOS: 'dashboard-banios',
  SURVEYS: 'surveys',
  SURVEY_STATS: 'survey-stats',
  CLIENT_ACCOUNTS: 'client-accounts',
  //SURVEYS_BUILDER: 'surveys-builder',
  ALERTS: 'alerts',
  CO_CREATION: 'co-creation',
  SOCIAL_LISTENING: 'social-listening',
  ACTION_PLANS: 'action-plans',
  REPORTS: 'reports',
  DASHBOARDAI: 'dashboard-ai',
  OMOIKANE_AI: 'omoikane-ai',
  POWER_INTELLIGENCE: 'power-intelligence',
  // DASHBOARDNEW: 'new-features',
  // SUPLIER_EXP: 'suplier-exp',
  // EMPLOYEE_EXP: 'employee-exp',

  LOGICAL_DISTRIBUTIONS: 'logical-distributions',
  GEOGRAPHIC_DISTRIBUTIONS: 'geo-distributions',
  CRITICAL_MOMENTS: 'critical-moments',
  //CUSTOMER_JOURNEYS: 'customer-journeys',
}

export const SECTION_ACTIONS = {
  [SECTION_KEYS.DASHBOARD]: { label: 'perms.main', value: 'main' },
  [SECTION_KEYS.EXTENDED_DASHBOARD]: { label: 'perms.main', value: 'main' },
  //[SECTION_KEYS.DASHBOARD_QR]: { label: 'perms.main', value: 'main' },
  [SECTION_KEYS.OMOIKANE_AI]: { label: 'perms.main', value: 'main' },
  [SECTION_KEYS.POWER_INTELLIGENCE]: { label: 'perms.main', value: 'main' },
  //[SECTION_KEYS.DASHBOARD_DISEL]: { label: 'perms.main', value: 'main' },
  //[SECTION_KEYS.DASHBOARD_BUILDER]: { label: 'perms.main', value: 'main', },
  //[SECTION_KEYS.DASHBOARD_BANIOS]: { label: 'perms.main', value: 'main' },
  [SECTION_KEYS.SURVEYS]: [
    { label: 'perms.main', value: 'main' },
    { label: 'perms.wizard', value: 'wizard' },
    { label: 'perms.admin', value: 'update' },
    { label: 'perms.delete', value: 'delete' },
  ],
  [SECTION_KEYS.SURVEY_STATS]: [
    { label: 'perms.main', value: 'main' }
  ],
  [SECTION_KEYS.CLIENT_ACCOUNTS]: [
    { label: 'perms.main', value: 'main' }
  ],
  [SECTION_KEYS.SOCIAL_LISTENING]: { label: 'perms.main', value: 'main' },
  // [SECTION_KEYS.SURVEYS_BUILDER]: {
  //   label: 'perms.main',
  //   value: 'main',
  // },
  [SECTION_KEYS.ALERTS]: [
    { label: 'perms.main', value: 'main' },
    { label: 'perms.stats', value: 'stats' },
    { label: 'perms.detail', value: 'detail' },
    { label: 'perms.close', value: 'update' },
    { label: 'perms.addComment', value: 'addComment' },
    { label: 'perms.addEvidence', value: 'addEvidence' },
    { label: 'perms.notifications', value: 'notifications' },
  ],
  [SECTION_KEYS.CO_CREATION]: { label: 'perms.main', value: 'main' },
  [SECTION_KEYS.ACTION_PLANS]: [
    { label: 'perms.main', value: 'main' },
    { label: 'perms.create', value: 'create' },
    { label: 'perms.add-activity', value: 'add-activity' },
    { label: 'perms.admin', value: 'update' },
    { label: 'perms.close', value: 'close' },
    { label: 'perms.root-cause', value: 'root-cause' },
    { label: 'perms.delete', value: 'delete' },
  ],
  //[SECTION_KEYS.DASHBOARDAI]: { label: 'perms.main', value: 'main' },

  // [SECTION_KEYS.DASHBOARDNEW]: { label: 'perms.main', value: 'main', },

  // [SECTION_KEYS.EMPLOYEE_EXP]: { label: 'perms.main', value: 'main', },

  // [SECTION_KEYS.SUPLIER_EXP]: { label: 'perms.main', value: 'main', },

  [SECTION_KEYS.REPORTS]: { label: 'perms.main', value: 'main' },

  // [SECTION_KEYS.CUSTOMER_JOURNEYS]: [
  //   { label: 'perms.main', value: 'main', },
  //   { label: 'perms.wizard', value: 'wizard', },
  //   { label: 'perms.update', value: 'update', },
  //   { label: 'perms.delete', value: 'delete', },
  // ],
}
