import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/social-listening',
  layout: true,
  getComponent: async () => {
    const SocialListeningLayout = await import('./components/SocialListeningLayout')
    const { reducer, rootSagas, } = await import('./SocialListeningModule')

    injectReducer(store, { key: 'social-listening', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: SocialListeningLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'SocialListening', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const SocialListeningView = await import('./components/SocialListeningView')
        return {
          children: SocialListeningView.default,
        }
      },
    },

    // {
    //   title: '', // Here you can use a plain string o a transalation key
    //   path: '/other-sub-path',
    //   getComponent: async () => {
    //     const OtherView = await import(
    //       /* webpackChunkName: "social-listening-modules"*/ './components/OtherView'
    //     )
    //
    //     return {
    //       children: OtherView.default,
    //     }
    //   }
    // },
  ],
})

