export default (): RouteConfig => ({
  path: '/recovery',
  getComponent: async () => {
    const { default: PasswordRecoveryView, } = await import(/* webpackChunkName: "reset-password" */ './components/PasswordRecoveryView')

    return {
      children: PasswordRecoveryView,
    }
  },
})
