import { widgetsBars } from './widgetsBars'
import { widgetsComments } from './widgetsComments'
import { widgetsGauge } from './widgetsGauge'
import { widgetsGraph } from './widgetsGraph'
import { widgetsIshikawa } from './widgetsIshikawa'
import { widgetsLine } from './widgetsLine'
import { widgetsMap } from './widgetsMap'
import { widgetsMultipleTrend } from './widgetsMultipleTrend'
import { widgetsPies } from './widgetsPies'
import { widgetsRanking } from './widgetsRanking'
import { widgetsSemicircle } from './widgetsSemicircle'
import { widgetsSentimentAnalysis } from './widgetsSentimentAnalysis'
import { widgetsTrend } from './widgetsTrend'

export const widgetsLibrary: CommonWidget = {
  ...widgetsBars,
  ...widgetsComments,
  ...widgetsGraph,
  ...widgetsGauge,
  ...widgetsIshikawa,
  ...widgetsLine,
  ...widgetsMap,
  ...widgetsMultipleTrend,
  ...widgetsPies,
  ...widgetsRanking,
  ...widgetsSentimentAnalysis,
  ...widgetsSemicircle,
  ...widgetsTrend,
}
