import React from 'react'
import { ReactComponent as NewDashboardIcon } from '../assets/menu/icon-dashboard.svg'
import { ReactComponent as NewSurveysIcon } from '../assets/menu/icon-encuesta.svg'
import { ReactComponent as NewStatisticsIcon } from '../assets/menu/icon-analisis.svg'
import { ReactComponent as NewAlertsIcon } from '../assets/menu/icon-gestion-alerta.svg'
import { ReactComponent as NewReportsIcon } from '../assets/menu/icon-reportes.svg'
import { ReactComponent as NewActionPlansIcon } from '../assets/menu/icon-planes-accion.svg'
import { ReactComponent as NewAiIcon } from '../assets/menu/icon-ai.svg'


const icons = {
  dashboard: (
    <NewDashboardIcon className="menu-icon" width={18} height={18} fill='#7B7B7B' />
  ),
  surveys: <NewSurveysIcon className="menu-icon" width={18} height={18} fill='#7B7B7B' />,
  statistics: (
    <NewStatisticsIcon className="menu-icon" width={18} height={18} fill='#7B7B7B' />
  ),
  alerts: <NewAlertsIcon className="menu-icon" width={24} height={24} fill='#7B7B7B' />,
  reports: <NewReportsIcon className="menu-icon" width={18} height={18} fill='#7B7B7B' />,
  actionPlans: (
    <NewActionPlansIcon className="menu-icon" width={18} height={18} fill='#7B7B7B' />
  ),
  iaMotor: <NewAiIcon className="menu-icon" width={24} height={24} fill='#7B7B7B' />,
}

export default icons
