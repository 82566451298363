import gasolina from './gasolina'
import selfService from './selfService'
import disel from './disel'
import qr from './qr'
import banios from './banios'
import { renderForCriticalMoment } from '../dashHelper'

export default {
  title: 'CXO Venta/Postventa Dashboard',
  description: 'Dashboard de CXO Venta/Postventa',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    ...gasolina.widgets(filters)
      //.filter((item: any, idx: number) => idx === 0)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [29]),
        config: {
          ...wdg.config,
          indicators: wdg.config?.indicators?.map((ind: any) => ({
            ...ind,
            extras: {
              ...ind.extras,
              criticalMomentInclude: [29],
            }
          }))
        }
      })),
    ...gasolina.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [1]),
      })),
    ...selfService.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11702]),
      })),
    ...disel.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11701]),
      })),
    ...qr.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11703]),
      })),
    ...banios.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [12951]),
      }))
  ],
} as DasboardConfig
