/* eslint no-param-reassign: ["error", { "props": false }] */
import { AnyAction, combineReducers, Reducer } from 'redux'
import { persistReducer, PURGE } from 'redux-persist'
import { connectRouter } from 'connected-react-router'
import config from '@config'
import history from '@utils/history'
import modules from '@modules'

const baseReducers = (
  asyncReducers?: Record<string, Reducer<any, ActionWithPayload>>,
) => {
  const reducers = combineReducers({
    router: connectRouter(history),
    ...modules.reducers,
    ...asyncReducers,
  })

  return reducers
}

export const makeRootReducer = (
  asyncReducers?: Record<string, Reducer<string, ActionWithPayload>>,
): Reducer<any, ActionWithPayload> => {
  return persistReducer(
    config.persistConfig,
    (state, action: AnyAction) => {
      if (action && action.type === PURGE) {
        // @ts-ignore: Because router action needs a payload
        return baseReducers(asyncReducers)(undefined, action)
      }

      // @ts-ignore
      return baseReducers(asyncReducers)(state, action)
    },
  )
}

/**
 * Inject a reducer in the store.
 * @param {Object} store  : Global store of the app.
 * @param {String} key    : Key that will have the reducer injected into the store.
 * @param {Object} reducer: Specefic reducer to inject in the store.
 */
export const injectReducer = (
  store: Store,
  { key, reducer, }: { key: string; reducer: Reducer<any, ActionWithPayload> },
): void => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
