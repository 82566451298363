import { DEFAULT_COLORS } from "@components/widgets/apacheEchart/Commons";

export const widgetsSentimentAnalysis: CommonWidget = {


  /**************************************************************************
   *                      SENTIMENT ANALISYS
  ***************************************************************************/

  textAnalysis: {
    title: 'Análisis de sentimientos',
    position: [0, 0],
    size: [4, 4],
    config: {
      id: 'text-analysis',
      type: 'textAnalysis',
      //colors: DEFAULT_COLORS,
      indicators: [
        {
          indicator: 'sentiment',
          grouped: 'tag',
          keyExtract: ['categories', 'count'],
          singleton: false,
          value: 'value',
          extras: {},
        },
      ],
    },
  },
}
