import { isString } from 'lodash'
import { createModule, STATUS } from 'redux-acron'
import i18n from 'i18next'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { call } from 'redux-saga/effects'
import { ActionCreatorsMapObject } from 'redux'

type PayloadType = {
  payload: {
    lang: string
  }
}

type State = {
  currentLang: string
}

type ImmutableState = Immutable<State>// ImmutableObjectMethods<State>

/**
 * Locale Module
 * reducer/sagas/actions
 */
const LocaleModule = createModule<State>({
  name: 'locale',
  state: {
    currentLang: 'es',
  },

  handlers: {
    changeLanguage: (state: ImmutableState, { payload, }: PayloadType) => {
      return state.merge({
        currentLang: payload.lang,
      })
    },
  },
  effects: {
    changeLanguage: {
      * callback(actions: ActionCreatorsMapObject, { payload, }: PayloadType) {
        if (isString(payload)) {
          i18n.changeLanguage(payload)
          dayjs.locale(i18n.language)
          yield call(actions.changeLanguage({}, STATUS.SUCCESS_STATUS))
        }
      },
    },
  },
})

export default LocaleModule
