import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import { ActionsDispatcher } from 'redux-acron'

export default function useActions(
  actions: Record<string, (o?: object) => void>,
  deps?: object[],
): ActionsDispatcher  {
  const dispatch = useDispatch()
  return useMemo(
    () => {
      // if (Array.isArray(actions)) {
      //   return actions.map((a) => bindActionCreators(a, dispatch))
      // }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps,] : [dispatch,],
  )
}
