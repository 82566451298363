import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, haTrendDataMapper, haWordCountWithLinksMapper  } from './commonWidgetsHa'

const allWidgets = [
  {
    title: 'NPS',
    type: 'label',
    position: [0, 0],
    size: [1, 1],
    config: { color: '#B7AADC' },
  },
  {
  title: 'Unidad de Referencia',
  type: 'label',
  position: [1, 0],
  size: [3, 1],
  config: { color: '#B7AADC' },
    },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'NPS',
    position: [0, 1],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_17',
    criticalMomentInclude: [12052],
    extraConfigs:{
        valueSymbol:''
     }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: 'Unidad de referencia',
    position: [1, 1],
    size: [3, 2],
    extraConfigs: {
      showSample: true,
      valueSymbol:''
    },
    indicator: 'convenios_subrogado_hemodialisis_pregunta_6',
    criticalMomentInclude: [12052]
  }),
  {
    title: 'Evaluación General',
    type: 'label',
    position: [0, 3],
    size: [4, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción general con el servicio recibido.',
    position: [0, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_9',
    criticalMomentInclude: [12052],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Comunicación efectiva para la mejora del servicio',
    position: [1, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_10',
    criticalMomentInclude: [12052],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Calidad de las instalaciones y suficiencia de equipos de la Unidad de Hemodiálisis',
    position: [2, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_11',
    criticalMomentInclude: [12052],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Seguimiento a las evaluaciones de servicio previas',
    position: [3, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_12',
    criticalMomentInclude: [12052],
    extraConfigs:{
        valueSymbol:''
    }
  }),
 {
    title: 'Evaluación General',
    type: 'label',
    position: [0, 6],
    size: [4, 1],
   },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Habilidades y conocimientos del personal Médico',
    position: [0, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_13',
    criticalMomentInclude: [12052],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Habilidades y conocimientos del personal de Enfermería',
    position: [1, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_14',
    criticalMomentInclude: [12052],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
     commonWidgets: commonWidgets,
     name: 'haTrendByGeo',
     title: 'Habilidades y conocimientos del personal de Atención a Pacientes',
     position: [2, 7],
     size: [1, 2],
     mapper: haTrendDataMapper,
     indicator: 'convenios_subrogado_hemodialisis_pregunta_15',
     criticalMomentInclude: [12052],
     extraConfigs:{
         valueSymbol:''
     }
   }),
  getCommonWidget({
     commonWidgets: commonWidgets,
     name: 'textAnalysis',
     position:[0,9], 
     size: [2, 3],
     indicator: 'SENTIMENT',
     criticalMomentInclude: [12052],
     extraConfigs:{
        hideBars: true
     }
   }),
  getCommonWidget({
     commonWidgets: commonWidgets,
     name: 'wordCountWithLinks',
     title: 'Nube de Palabras / Frecuencia en comentarios',
     position: [2, 9],
     size: [2, 3],
     mapper: haWordCountWithLinksMapper,
     criticalMomentInclude: [12052]
   }),
   
]

export default {
  title: 'Hospital Angeles Convenios Servicio Subrogado de Hemodiálisis',
  description: 'Hospital Angeles Convenios Servicio Subrogado de Hemodiálisis',
  widgets: (filters: FiltersUI) => {
    return allWidgets
  },
} as DasboardConfig
