

export const widgetsIshikawa: CommonWidget = {

  /**************************************************************************
   *                          ISHIKAWA
  ***************************************************************************/

  diagramaIshikawa: {
    position: [0, 0],
    size: [4, 3],
    config: {
      id: 'ishikawa-sentiment',
      type: 'fishBone',
      indicators: [
        {
          indicator: 'sentiment',
          grouped: 'criticalMoment,tag',
          //grouped: 'tag',
          keyExtract: ['group', 'tags', 'count'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            resultType: 'bottom'
          },
        },
      ],
    },
  },
  diagramaIshikawaByLogical: {
    title: 'Diagrama Ishikawa',
    position: [0, 0],
    size: [4, 3],
    config: {
      id: 'ishikawa-sentiment',
      type: 'fishBone',
      indicators: [
        {
          indicator: 'sentiment',
          grouped: 'logicalLocation,tag',
          //grouped: 'tag',
          keyExtract: ['group', 'tags', 'count'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            resultType: 'bottom',
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
}
