import React from 'react'

const ErrorsLayout = (): JSX.Element => {
  return (
    <div className='errors-layout'>
      Error
    </div>
  )
}

export default ErrorsLayout
