import useClientConfig from 'config/clients/useClientConfig'
import dayjs from 'dayjs'
import _ from 'lodash'

/**
 * Parse the filters names and formats.
 * @param {Object} filters
 * @returns {Object}
 */
export default (filters: FiltersUI, extras?: any): Filters => {
  const {
    includesFilterGeo,
    includesFilterLogical,
    includesFilterCriticalMoment,
    includesFilterDynamic
  } = useClientConfig()

  //console.log('Parsing filters')
  //console.log(filters)

  if (!filters) {
    return {}
  }

  const dates = {
    dateFrom: dayjs(filters.fromDate).format('YYYY-MM-DD'),
    dateTo: dayjs(filters.toDate).format('YYYY-MM-DD'),
  }

  const clear = (filters: FiltersUI): Filters => {
    const safetyFilters = [
      'groupByTopLocation',
      'alertStatusInclude',
      'alertTypeInclude',
      'status',
      'locale'
    ]
    return _.pick(filters, safetyFilters)
  }

  const createCustomFilters = (filters: any) => {
    const filter =
      filters &&
        Object.keys(filters).length > 0 &&
        Object.keys(filters).filter((key: any) => filters[key] !== undefined)
          .length > 0
        ? {
          filter: Object.keys(filters)
            .filter(
              (key: any) =>
                ['login', 'email', 'description', 'code'].includes(key) &&
                filters[key] !== undefined
            )
            .map((key: any) => key + '=eq=%' + filters[key] + '%')
            .join(';'),
        }
        : {}

    return filter
  }


  // Critical Moments
  const criticalMomentFilters = includesFilterCriticalMoment()
    ? {
      criticalMomentInclude: filters.criticalMoments
        ? filters.criticalMoments.join(',')
        : ''
    } : {}

  // Geo Locations
  const geoLocationFilters = includesFilterGeo()
    ? {
      geoLocationInclude: filters.geographicDistributions
        ? filters.geographicDistributions.join(',') : ''
    } : {}

  // Logical Locations
  const logicalLocationFilters = includesFilterLogical()
    ? {
      logicalLocationInclude: filters.logicalDistributions
        ? filters.logicalDistributions.join(',') : ''
    } : {}

  // Response values
  const responseValues = {}
  if (includesFilterDynamic()) {
    var rvs = {}

    if (includesFilterDynamic() && filters.responseValues !== undefined) {
      rvs = { ...rvs, ...filters.responseValues }
    }

    if (extras && extras.extraResponseValues) {
      rvs = { ...rvs, ...extras.extraResponseValues }
    }

    responseValues['responseValues'] = Object.keys(rvs).length > 0 ? rvs : undefined
  }

  return {
    ...dates,
    ...clear(filters),
    ...criticalMomentFilters,
    ...geoLocationFilters,
    ...logicalLocationFilters,
    ...responseValues,
    ...createCustomFilters(filters),
  }
}
