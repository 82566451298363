import {PRODUTURO_SCALE_3_COLORS,PRODUTURO_SENTIMENT_ANAL_COLORS} from '../commonWidgetsProfuturo'
import Color from 'color'

const GENERAL_INDICATORS = {
  ATENCION_GENERAL: 'Atención',
  CSAT_GENERAL: 'Satisfacción',
  FACILIDAD_GENERAL: 'Facilidad',
  NPS_GENERAL: 'NPS',
  TIEMPO_ESPERA_GENERAL: 'Satisfacción tiempo de espera',
}

export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    {
      title: 'NPS',
      position: [0, 0],
      size: [4, 2],
      config: {
        id: 'satisfaccion-nps-general-byGeo',
        type: 'dataTable',
        color: ['#CCC'],
        enableArcLabels: true,
        valueFormat: ' >-.0%',
        groupName: 'Campaña',
        indicators: [
          {
            indicator: 'NPS_GENERAL',
            label: 'group',
            value: 'value',
            grouped: 'formattedMonth,criticalMoment',
            singleton: true,
            keyExtract: ['group', 'value', 'groupName', 'group_0', 'group_1'],
            extras: {
              periods: 10
            }
          }
        ]
      }
    },
    {
      title: 'Indicadores generales',
      helpLink: '--',
      position: [0, 2],
      size: [4, 2],
      config: {
        id: 'general-indicators',
        type: 'dataTable',
        color: ['#CCC'],
        enableArcLabels: true,
        valueFormat: ' >-.0%',
        groupName: 'Campaña',
        mapper: (data: any): any => {
          //console.log('general-indicators')
          //console.log(data)
          return data.map((item: any) => {
            return ['ATENCION_GENERAL', 'CSAT_GENERAL', 'FACILIDAD_GENERAL',
              'NPS_GENERAL', 'TIEMPO_ESPERA_GENERAL']
              .map((ind: string) => ({
                'group_1': item.criticalMomentDescription,
                'group_0': ind,
                groupName:  GENERAL_INDICATORS[ind],
                value: item[ind]
            }))
          }).flat(1)
        },
        indicators: [
          {
            indicator: 'NPS_GENERAL',
            url: 'reports/indicators-dashboard',
            label: 'group',
            value: 'value',
            singleton: true,
            keyExtract: [
              'ATENCION_GENERAL',
              'CSAT_GENERAL',
              'FACILIDAD_GENERAL',
              'NPS_GENERAL',
              'TIEMPO_ESPERA_GENERAL',
              'criticalMoment',
              'criticalMomentDescription'
            ],
            extras: {
              periods: 10,
              resultType: 'JSON'
            }
          }
        ]
      }
    },
    {
      title: 'NPS por Ejecutivo',
      position: [0, 4],
      size: [4, 2],
      config: {
        id: 'satisfaccion-nps-general-by-ejecutivo',
        type: 'dataTable',
        color: ['#CCC'],
        enableArcLabels: true,
        valueFormat: ' >-.0%',
        groupName: 'Campaña',
        indicators: [
          {
            indicator: 'NPS_GENERAL',
            key: 'nps-general-ejecutivo-profuturo',
            label: 'group',
            value: 'value',
            grouped: 'formattedMonth,responseValue',
            groupByResponseValueId: 173002100,
            singleton: true,
            keyExtract: ['group', 'value', 'groupName', 'group_0', 'group_1'],
            extras: {
              periods: 10,
              groupNameFormat: "description"
            }
          }
        ]
      }
    },
    {
      title: 'Comentarios',
      position: [0, 6],
      size: [4, 2],
      config: {
        id: 'WORD_COUNT',
        type: 'wordCloud',
        indicators: [
          {
            indicator: 'WORD_COUNT',
            keyExtract: ['count', 'value', 'group'],
            singleton: true
          }
        ]
      }
    },
    {
      title: 'Análisis de Sentimientos',
      config: {
        id: 'text-analysis',
        type: 'textAnalysis',
        colors: PRODUTURO_SCALE_3_COLORS
        .map((color: string) => Color(color).alpha(0.5).toString()),
       /* groupLabels:  { positive: 'Positivos',
  					    neutral: 'Neutros',
  					    negative: 'Negativos',},
  		*/			    
  		groupKeys: ['positive', 'neutral','negative'],			    
        indicators: [{
          indicator: '999',
          keyExtract: ['categories'],
          grouped: 'tag',
          value: 'value',
          singleton: false,
          extras: {}
        }]
      },
     
      position: [0, 8],
      size: [4, 4]
    }
  ]
} as DasboardConfig