import { getCmID } from './commonWidgetsSuzuki'
import cxoResume from './cxoResume'
import nacionalPosventa from './nacionalPosventa'
import nacionalVenta from './nacionalVenta'

export default {
  title: 'CXO Venta/Postventa Dashboard',
  description: 'Dashboard de CXO Venta/Postventa',
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) => [
    ...cxoResume.widgets(filters).map((wdg) => ({
      ...wdg,
      render: (filters: FiltersUI) =>
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(getCmID('root')),
    })),
    ...nacionalVenta.widgets(filters).map((wdg) => ({
      ...wdg,
      render: (filters: FiltersUI) =>
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(getCmID('venta')),
    })),
    ...nacionalPosventa.widgets(filters).map((wdg) => ({
      ...wdg,
      render: (filters: FiltersUI) =>
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(getCmID('servicio')),
    })),
  ],
} as DasboardConfig
