import apertura from './apertura'
import lanzamientos from './lanzamientos'
import resume from './resume'
import satisfaction from './satisfaction'
import experienciaCompra from './experienciaCompra'

export const caffenioStaticDashboards: Record<string, DasboardConfig> = {
  '10252': {
    title: 'Apertura Dashboard',
    description: 'Apertura Dashboard',
    widgets: apertura.widgets,
  },
  '10251': {
    title: 'Lanzamientos Dashboard',
    description: 'Lanzamientos Dashboard',
    widgets: lanzamientos.widgets,
  },
  '10601': {
    title: 'Satisfacción Dashboard',
    description: 'Satisfacción Dashboard',
    widgets: satisfaction.widgets,
  },
  '13251': {
    title: 'Satisfacción Dashboard',
    description: 'Satisfacción Dashboard',
    widgets: experienciaCompra.widgets,
  },
  '0': {
    title: 'General Dashboard',
    description: 'General Dashboard',
    widgets: resume.widgets,
  },
  '29': {
    title: 'General Dashboard',
    description: 'General Dashboard',
    widgets: resume.widgets,
  }
}