import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/critical-moments',
  layout: true,
  getComponent: async () => {
    const CriticalMomentsLayout = await import(
      /* webpackChunkName: "critical-moments-modules" */ './components/CriticalMomentsLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "critical-moments-modules" */ './CriticalMomentsModule'
    )

    injectReducer(store, { key: 'criticalMoments', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: CriticalMomentsLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'critical_moments.list_title',
      getComponent: async () => {
        const CriticalMomentsListView = await import(
          /* webpackChunkName: "critical-moments-modules" */ './components/CriticalMomentsListView'
        )
        return {
          children: CriticalMomentsListView.default,
        }
      },
    },

    {
      title: 'critical_moments.new_title',
      path: '/new',
      getComponent: async () => {
        const CriticalMomentsFormView = await import(
          /* webpackChunkName: "critical-moments-modules" */ './components/CriticalMomentsFormView'
        )
  
        return {
          children: CriticalMomentsFormView.default,
        }
      },
    },

    {
      title: 'critical_moments.new_title',
      path: '/edit/:id',
      getComponent: async () => {
        const CriticalMomentsFormView = await import(
          /* webpackChunkName: "critical-moments-modules" */ './components/CriticalMomentsFormView'
        )
  
        return {
          children: CriticalMomentsFormView.default,
        }
      },
    },
  ],
})
