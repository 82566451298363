import React, { useState } from 'react'
import {
  Button,
  Badge,
  Drawer,
  Row,
  Col,
  Card,
  Spin,
  Radio,
  RadioChangeEvent,
} from 'antd'
import _ from 'lodash'
import dayjs from 'dayjs'
import useConnection from '@hooks/useConnection'
import { useHistory } from 'react-router-dom'
import useActions from '@hooks/useActions'
import useFilters from '@hooks/useFilters'
import modules from '@modules'
import { BellOutlined } from '@ant-design/icons'

import './AlertsNotification.less'

const AlertsNotification = (): JSX.Element => {
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
  const history = useHistory()
  const dispatchers = useActions(modules.actions.appData, [])
  const { filters } = useFilters()
  const { alertsCount, loadingAlertsCount, countersRange } = useConnection<{
    alertsCount: Record<string, number>
    loadingAlertsCount: boolean
    countersRange: number
  }>([
    'appData.alertsCount',
    'appData.loadingAlertsCount',
    'appData.countersRange',
  ])

  const total = React.useCallback(() => {
    return _.reduce(
      alertsCount,
      (res, counter) => {
        res += counter
        return res
      },
      0
    )
  }, [loadingAlertsCount, alertsCount])

  const onChangeFilter = React.useCallback((e: RadioChangeEvent) => {
    dispatchers.getAlertsCount({
      filters: {
        ...filters,
        fromDate: dayjs().subtract(e.target.value, 'days').format('YYYY-MM-DD'),
        toDate: dayjs().format('YYYY-MM-DD'),
      },
      range: e.target.value,
    })
  }, [])

  const goToAlertList = React.useCallback(() => {
    setDrawerVisible(false)
    history.push('/alerts/list')
  }, [history])

  return (
    <div className='alerts-notification'>
      <Badge count={0} offset={[2, 5]} className='alert-count-badge'>
        <Button
          icon={<BellOutlined style={{ fontSize: 20, color: '#BAAEE5' }} />}
          type='link'
          onClick={() => setDrawerVisible(true)}
        />
      </Badge>

      <Drawer
        className='alerts-drawer'
        title=''
        width={500}
        closable
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        <Spin spinning={loadingAlertsCount}>
          <h2>{total()} Alertas</h2>

          <div className='flex flex-horizontal flex-center'>
            <Radio.Group
              onChange={onChangeFilter}
              value={countersRange.toString()}
              size={'small'}
            >
              <Radio.Button value='7'>7 días</Radio.Button>
              <Radio.Button value='14'>14 días</Radio.Button>
              <Radio.Button value='30'>30 días</Radio.Button>
              <Radio.Button value='60'>60 días</Radio.Button>
              <Radio.Button value='90'>90 días</Radio.Button>
              <Radio.Button value='180'>6 meses</Radio.Button>
            </Radio.Group>
          </div>

          <Row>
            <Col xs={{ span: 6 }}>
              <Card className='alert-counter alert-counter-not-handled'>
                <span className='counter'>{alertsCount.not_handled}</span>
                <span className='label'>No gestionadas</span>
              </Card>
            </Col>
            <Col xs={{ span: 6 }}>
              <Card className='alert-counter alert-counter-in-proccess'>
                <span className='counter'>{alertsCount.in_process}</span>
                <span className='label'>En Proceso</span>
              </Card>
            </Col>
            <Col xs={{ span: 6 }}>
              <Card className='alert-counter alert-counter-closed'>
                <span className='counter'>{alertsCount.closed}</span>
                <span className='label'>Cerradas</span>
              </Card>
            </Col>

            <Col xs={{ span: 6 }}>
              <Card className='alert-counter alert-counter-wows'>
                <span className='counter'>{alertsCount.wows}</span>
                <span className='label'>WOWs</span>
              </Card>
            </Col>
          </Row>

          <div className='flex flex-horizontal flex-center'>
            <Button type='primary' size='large' onClick={goToAlertList}>
              Ver detalle
            </Button>
          </div>
        </Spin>
      </Drawer>
    </div>
  )
}

export default AlertsNotification
