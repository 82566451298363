import React, { JSXElementConstructor, ReactElement } from 'react'
import { Switch, Route } from 'react-router-dom'
import LoadableComponent from './LoadableComponentComplex'

type Routes = (Route<Record<string, never>, string> | ReactElement<any, string | JSXElementConstructor<any>>)[]

/**
 * Extract any valid prop pased on RouterConfig
 * @param props RouterConfig
 * @returns Object valid props allowed
 */
const extractValidProps = (props: RouteConfig) => {
  const valid = ['title', 'onEnter', 'path',]
  const result = {}

  valid.forEach((key) => {
    if (props[key]) {
      result[key] = props[key]
    }
  })

  return result
}

/**
 * Helper function to parse the config object to react-router navigation components.
 * (recursive)
 * @param config RouterConfig 
 * @param basePath String
 */
const parseConfig = (config: RouterConfig, parentConfig?: RouteConfig, level=0): Routes => {
  const routes = config.reduce((res: Array<Route|React.ReactElement>, cfg) => {
    const currentPath = cfg.path ? 
      `${ parentConfig && parentConfig.path !== '/' ? parentConfig.path : ''}${cfg.path}`: 
      ''

    if ((cfg.layout) && cfg.childRoutes) {
      const Layout = (props: object) : JSX.Element => <LoadableComponent 
        component={cfg.component}
        getComponent={cfg.getComponent}
        {...props}
        {...extractValidProps(cfg)}
      />

      const childRoutes = parseConfig(cfg.childRoutes, {
        ...cfg,
        parentLayout: Layout,
        path: currentPath,
      }, level+1)

      res.push(
        <Route key={`path-${level}-${cfg.path}`} path={currentPath}>
          <Layout>
            <Switch>{childRoutes}</Switch>
          </Layout>
        </Route>,
      )
    } else {
      res.push(
        <Route
          key={`path-${level}-${cfg.path}`}
          exact
          path={currentPath}
        >
          <LoadableComponent
            component={cfg.component}
            getComponent={cfg.getComponent}
            {...extractValidProps(cfg)}
          />
        </Route>,
      )
    }

    return res
  }, [])

  return routes
}

export default parseConfig
