
import logoBydSmall from '@assets/logos/byd-logo-small.png'
import logoByd from '@assets/logos/byd-logo.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments } from '../dashboard/dashHelper'
import { omoikaneByd } from '../omoikane/omoikaneByd'
import deafultHelpDefinition from '../help/defaultHelp'

export const bydConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://byd-backend-voc.limetropy.com/api/',
    // Epsilon back
    //vocBackendUrl: 'https://byd-epsilon-backend-voc.limetropy.com/api/',
    lcmBackendUrl: '',
    //vocBackendUrl: 'http://localhost:9000/api/',
    publicUrl: '/voc',
    logoSmall: logoBydSmall,
    logo: logoByd,
    filtersFromDateSubstract: 2,
    filtersToDateSubstract: 0,
    decimals: undefined,
    supportsInternationalization: true,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.GEOS, FILTER_TYPES.CRITICAL_MOMENTS],
    menuDashboards: [DASHBOARDS.DEFAULT],
    msExcelReportExt: 'xls',
    geosRootId: 10001,
    logicalsRootId: 0,
    criticalMomentsRootId: 0,
    criticalMomentFilterType: 'select',
    actionPlans: {
        geoLeafLevel: 'BRANCH',
        useWorstTags: true
    },
    sentimentCommentsIndicatorName: 'sentiment-comments',
    omoikane: omoikaneByd,
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.byd,
        default: DASHBOARDS_CONFIGS.byd[DASHBOARDS.DEFAULT]
    },
    staticDashboards: {},
    surveyResponses: {
        url: 'survey-responses',
        type: 'post',
        tableColumns: undefined,
        tabs: [SurveyResponsesTab.ANSWERED/*, SurveyResponsesTab.NOT_ANSWERED*/],
    },
    alertDetailFields: [],
    help: deafultHelpDefinition,
    /*¿esto podria venir del back tomando el orden del campaing, cuando byd lo tenga parametrizado? */
    questionsOrder: [{questionName:'POSVENTA_AGENDAR_CITA',order: 1},   
                     {questionName:'POSVENTA_AGENDAR_CITA_PROBLEMA', order: 2},
                     {questionName:'POSVENTA_AGENDAR_CITA_PROBLEMA_OTRO', order: 3},
                     {questionName:'POSVENTA_RECEPCION_VEHICULO', order: 4 },
                     {questionName:'POSVENTA_RECEPCION_VEHICULO_COMENTARIO', order: 5 },
                     {questionName:'POSVENTA_OFRECIERON_BEBIDA', order:  6 },
                     {questionName:'POSVENTA_EXPLICACION_TRABAJO', order:  7},
                     {questionName:'POSVENTA_INSPECCION_VEHICULO', order: 8},
                     {questionName:'POSVENTA_SEGUIMIENTO', order: 9 },
                     {questionName:'POSVENTA_PUNTUALIDAD', order: 10},
                     {questionName:'POSVENTA_EXPLICACION', order: 11 },
                     {questionName:'POSVENTA_REPARACION_VEHICULO_2', order: 12},
                     {questionName:'POSVENTA_REPARACION_VEHICULO_PROBLEMA', order: 13},
                     {questionName:'POSVENTA_REPARACION_VEHICULO_PROBLEMA_OTRO', order: 14 },
                     {questionName:'POSVENTA_REQUIRIO_ADICIONAL', order: 15 },
                     {questionName:'POSVENTA_ADICIONAL_CORRECTO', order:  16 },
                     {questionName:'POSVENTA_ADICIONAL_INCORRECTO', order: 17 },
                     {questionName:'POSVENTA_ADICIONAL_INCORRECTO_OTROS', order: 18},
                     {questionName:'POSVENTA_MOSTRARON_REFACCIONES', order: 19 },
                     {questionName:'POSVENTA_PRECIO_JUSTO', order: 20},
                     {questionName:'POSVENTA_AMBIENTE', order: 21 },
                     {questionName:'POSVENTA_SATISFACCION', order: 22 },
                     {questionName:'POSVENTA_SUGERENCIA', order: 23},
                     {questionName:'POSVENTA_QUEJA', order: 24},
                     {questionName:'POSVENTA_FELICITACION', order: 25 },
                     {questionName:'POSVENTA_RECOMENDACION', order: 26 },
                     {questionName:'POSVENTA_COMENTARIO', order: 27 }
                 ]
}
