import { groupMapper } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from './commonWidgetsOxxo'

export default {
  title: 'Preguntas complementarias',
  description: 'Preguntas complementarias',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      //name: 'pieGenericMedium',
      name: 'generalSatisfactionDiesel',
      title: 'Satisfacción general Diésel',
      position: [0, 0],
      size: [2, 2],
      mapper: (data: any) => {
        return [
          {
            id: 0,
            value: data[0].value,
            label: 'satisfecho',
            group: 'satisfecho',
          },
          {
            id: 1,
            value: 100 - data[0].value,
            label: 'insatisfecho',
            group: 'insatisfecho',
          },
        ]
      },
      indicator: 'satisfaction',
      criticalMomentInclude: [11701],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Cuál fue tu forma de pago?',
      position: [2, 0],
      size: [2, 2],
      indicator: 'OXXO_DIESEL_PAYMENT_WAY',
      mapper: groupMapper,
      criticalMomentInclude: [11701],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Al llegar a la bomba de diésel cuánto tiempo esperaste a ser atendido por uno de nuestros despachadores?',
      position: [0, 2],
      size: [2, 2],
      mapper: groupMapper,
      indicator: 'OXXO_DIESEL_WAIT_TIME',
      criticalMomentInclude: [11701],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'De acuerdo a lo anterior, ¿cómo percibes que fue el tiempo de espera?',
      position: [2, 2],
      size: [2, 2],
      mapper: groupMapper,
      indicator: 'OXXO_DIESEL_WAIT_TIME_FEEL',
      criticalMomentInclude: [11701],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Al momento de realizar la transacción con tu forma de pago, ¿tuviste algún conflicto?',
      position: [0, 4],
      size: [2, 2],
      indicator: 'OXXO_DIESEL_PAYMENT_ISSUE',
      criticalMomentInclude: [11701],
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'En caso de que sí, menciónanos cuál fue la problemática que te indicó la estación',
      position: [2, 4],
      size: [2, 2],
      mapper: groupMapper,
      indicator: 'OXXO_DIESEL_PAYMENT_ISSUE_OPTIONS',
      criticalMomentInclude: [11701],
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Cómo calificas la agilidad del despachador?',
      position: [0, 6],
      size: [2, 2],
      mapper: groupMapper,
      indicator: 'OXXO_DIESEL_PERSONAL_AGILE',
      criticalMomentInclude: [11701],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'En general, ¿cómo calificas la amabilidad del despachador?',
      position: [2, 6],
      size: [2, 2],
      mapper: groupMapper,
      indicator: 'OXXO_DIESEL_PERSONAL_CARE',
      criticalMomentInclude: [11701],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Cuéntanos qué nos hace falta para mejorar nuestro servicio',
      position: [0, 8],
      size: [2, 2],
      mapper: groupMapper,
      indicator: 'OXXO_DIESEL_IMPROVE_SERVICE',
      criticalMomentInclude: [11701],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'De los siguientes servicios cuáles serían los que más te interesarían:',
      position: [2, 8],
      size: [2, 2],
      mapper: groupMapper,
      indicator: 'OXXO_DIESEL_INTEREST_SERVICE',
      criticalMomentInclude: [11701],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Adicional del cobro y despacho de tu combustible selecciona los servicios que te gustaría que te incluyéramos en cada visita',
      position: [0, 10],
      size: [2, 2],
      mapper: groupMapper,
      indicator: 'OXXO_DIESEL_ADD_SERVICES',
      criticalMomentInclude: [11701],
    }),
  ],
} as DasboardConfig
